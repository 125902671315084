import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-angular-environment',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './angular-environment.component.html',
  styleUrls: ['./angular-environment.component.scss'],
})
export class AngularEnvironmentComponent {
  environment = environment;
  objectKeys = Object.keys;
}
