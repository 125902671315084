import { ReferralComponent } from './referral/referral.component';
import { CustomProjectSiteComponent } from './dashboard/projects/custom-project-site/custom-project-site.component';
import { AboutComponent } from './about/about.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { ImprintAndDataPrivacyComponent } from './imprint-and-data-privacy/imprint-and-data-privacy.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthComponent } from './auth/auth.component';
import { WorkComponent } from './work/work.component';
import { ContactComponent } from './contact/contact.component';
import { HelloComponent } from './hello/hello.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { ProjectDetailsComponent } from './project-details/project-details.component';

const isMaintenanceMode = false;
const maintenanceRoutes: Routes = [
  {
    path: '',
    redirectTo: '/maintenance',
    pathMatch: 'full'
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent
  },
  {
    path: 'imprint-and-data-privacy',
    component: ImprintAndDataPrivacyComponent
  },
  {
    path: '**',
    redirectTo: '/maintenance'
  }
];

const routes: Routes = [
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'auth',
    component: AuthComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard/custom-project-site',
    component: CustomProjectSiteComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'hello',
    component: HelloComponent
  },
  {
    path: 'hello/:anchor',
    component: HelloComponent,
  },
  {
    path: 'contact',
    component: ContactComponent
  }, {
    path: 'auth',
    component: AuthComponent
  }, {
    path: 'imprint-and-data-privacy',
    component: ImprintAndDataPrivacyComponent
  },
  {
    path: 'ref/:referringItem',
    component: ReferralComponent
  },
  {
    path: 'work',
    component: WorkComponent
  }, {
    path: 'work/:projectLinkName',
    component: ProjectDetailsComponent,
  },
  { path: '',
    redirectTo: '/hello',
    pathMatch: 'full'
  },
  // { path: '**', component: PageNotFoundComponent }
  { path: '**',
    redirectTo: '/hello',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(
    isMaintenanceMode ? maintenanceRoutes : routes,
    {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
}
  )],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor() {
    // console.log('isMaintenanceMode: ' + isMaintenanceMode);
  }
}
