import { Injectable, signal, WritableSignal, effect } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  public currentLanguage: WritableSignal<string> = signal(this.translate.currentLang || this.translate.defaultLang);

  constructor(private translate: TranslateService) {
    const savedLanguage = localStorage.getItem('currentLanguage');
    if (savedLanguage) {
      this.currentLanguage.set(savedLanguage);
    }
    this.translate.use(this.currentLanguage());

    effect(() => {
      const lang = this.currentLanguage();
      localStorage.setItem('currentLanguage', lang);
      this.translate.use(lang);
    });
  }

  changeLanguage(lang: string): void {
    if (lang !== this.currentLanguage()) {
      this.currentLanguage.set(lang);
    }
  }
}

export enum FSLanguage {
  German = 'de',
  English = 'en'
}
