import { referrals, ReferralsDataSource } from './../../data/referrals.data';
import { faLinkedinIn, faXing, faGithub } from '@fortawesome/free-brands-svg-icons';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-what-do-next',
  templateUrl: './what-do-next.component.html',
  styleUrls: ['./what-do-next.component.scss']
})
export class WhatDoNextComponent implements OnInit {

  @Input() options: DoNextOptions = null;

  nextAbout = false;
  nextWork = false;
  nextContact = false;
  nextSocialConnect = false;

  icons = {
    gitHub: faGithub,
    linkedinIn: faLinkedinIn,
    xing: faXing
  };

  public referrals: ReferralsDataSource;

  constructor() { }

  ngOnInit() {
    this.referrals = referrals;
  }

}

export interface DoNextOptions {
  about: boolean;
  work: boolean;
  contact: boolean;
  socialConnect: boolean;
}
