import { Injectable } from '@angular/core';
import { DocumentReference } from '@angular/fire/compat/firestore';
import { getFirestore, doc, getDoc, setDoc, Firestore, updateDoc, collection, getDocs, query, where } from "firebase/firestore"
import { isProduction } from 'src/app/core/common.utils';
import { CVInformation, CVLangLocale } from 'src/app/model/FbDatabase/cv-information.model';

@Injectable({
  providedIn: 'root'
})
export class FirebaseFirestoreDatabaseService {

  constructor(
    private fbDatabase: Firestore
  ) {
  }

  // Public

  async getAllCVInformations(): Promise<CVInformation[]> {
    try {
      // All cv documents in the files or files-dev folder, begins  with 'cv'
      const querySnapshot = await getDocs(
        query(collection(this.fbDatabase, this.filesFolderName), where('__name__', '>=', 'cv'), where('__name__', '<', 'cv~'))
      )
      return querySnapshot.docs.map(doc => doc.data() as CVInformation)
    } catch (error) {
      console.error('getAllCVInformations() failed: ', error)
      return []
    }
  }

  async getCVInformation(cvLangLocale: CVLangLocale): Promise<CVInformation | undefined> {
    try {
      return (await getDoc(this.cvDocReference(cvLangLocale))).data() as CVInformation
    } catch (error) {
      console.error('getCVInformation() failed: ', error)
      return undefined
    }
  }

  async updateCVInformation(cvInformation: CVInformation): Promise<void> {
    try {
      await setDoc(this.cvDocReference(cvInformation), cvInformation)
    } catch { error => {
      console.error('updateCVInformation() failed: ', error)
      throw error
    }}
  }

  // Private

  private filesFolderName = isProduction() ? 'files' : 'files-dev'
  private cvDocPrefix = 'cv'

  private cvDocReference(cvLangLocale: CVLangLocale) {
    return doc(
      this.fbDatabase,
      this.filesFolderName,
      `${this.cvDocPrefix}-${cvLangLocale.language}-${cvLangLocale.locale}`
    )
  }
}
