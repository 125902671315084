<div id="client-logos"
     class="d-flex flex-wrap justify-content-center align-items-center gap-3 gap-md-5"
>
  @for(client of clients; track client.shortname; let i = $index) {
    <img src='../../assets/images/client-logo-{{client.shortname}}.{{client.clientLogoFiletype}}'
         alt='{{client.shortname}} Logo'
         class="client-logo desaturate not-selectable"
         draggable="false"
         [@enter]="{ value: '', params: { delay: i * 200 } }"
    />
  }
</div>
