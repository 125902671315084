import { FirebaseStorageService } from './firebase-storage.service';
import { Project } from './../../model/model.vo';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { isProduction, isDef } from 'src/app/core/common.utils';
import { BehaviorSubject, Observable, catchError, of, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  public projectsCollection$: AngularFirestoreCollection<Project>;
  public projects$: BehaviorSubject<Project[]> = new BehaviorSubject([]);

  constructor(
    private firestore: AngularFirestore,
    private storageService: FirebaseStorageService
  ) {
    this.projectsCollection$ = this.firestore.collection(isProduction() ? 'projects' : 'projects-dev');

    this.projectsCollection$.valueChanges().subscribe(projects => {
      this.projects$.next(projects);
    });

    this.projects$.subscribe(projects => {
      this.fetchImageURLsIfNeeded(projects);
    });
  }

  public project(linkName: string): Project | undefined {
    return this.projects$.getValue().find(project => project.linkName === linkName) || undefined;
  }

  public fetchProject(linkName: string): Promise<Project> {
    return new Promise<Project>((resolve, reject) => {
      this.projectsCollection$.doc(linkName).get().subscribe(doc => {
        if (doc.exists) {
          const fetchedProject = doc.data() as Project;

          this.storageService.fetchProjectImageURL(fetchedProject.linkName).subscribe(imageURL => {
            fetchedProject.imageURL = imageURL;

            // Project with ImageURL
            resolve(fetchedProject);
          }, error => {
            fetchedProject.imageURL = '';

            // Project without ImageURL
            resolve(fetchedProject);
          });
        } else {
          reject('Project document not exist');
        }
      }, error => reject(error) );
    });
  }

  public createProject(project: Project): Promise<void> {
    return this.projectsCollection$.doc(project.linkName).set(
      JSON.parse(JSON.stringify(project))
    );
  }

  public updateProject(project: Project): Promise<void> {
    return this.projectsCollection$.doc(project.linkName).set(
      JSON.parse(JSON.stringify(project)),
      { merge: true }
    );
  }

  public deleteProject(project: Project): Promise<void> {
    return this.projectsCollection$.doc(project.linkName).delete();
  }

  public deleteAllProjects() {
    this.projectsCollection$.get().subscribe(querySnapshot => {
      querySnapshot.forEach(project => {
        project.ref.delete();
      });
    });
  }

  public sortProjects(projects: Project[], asc: boolean = false): Project[] {
    return projects.sort((p1: Project, p2: Project) => {
      switch (asc) {
        case true:
          return new Date(p1.start).getTime() - new Date(p2.start).getTime();
        case false:
          return new Date(p2.start).getTime() - new Date(p1.start).getTime();
      }
    });
  }

  public projectsSorted(asc: boolean = false): Project[] {
    return this.projects$.getValue().sort((p1: Project, p2: Project) => {
      switch (asc) {
        case true:
          return new Date(p1.start).getTime() - new Date(p2.start).getTime();
        case false:
          return new Date(p2.start).getTime() - new Date(p1.start).getTime();
      }
    });
  }

  public previousProjectOfProject(project: Project) {
    if (this.projects$.getValue()) {
      const sortedProjects = this.projectsSorted(true);

      const indexOfCurrentProject = sortedProjects.findIndex(p => project.shortName === p.shortName);

      switch (indexOfCurrentProject) {
        case -1: return null;
        case 0: return null;
        default: return sortedProjects[indexOfCurrentProject - 1];
      }
    }
    return null;
  }

  public nextProjectOfProject(project: Project) {
    if (this.projects$.getValue()) {
      const sortedProjects = this.projectsSorted(true);
      const indexOfCurrentProject = sortedProjects.findIndex(p => project.shortName === p.shortName);

      switch (indexOfCurrentProject) {
        case -1: return null;
        case (sortedProjects.length - 1): return null;
        default: return sortedProjects[indexOfCurrentProject + 1];
      }
    }
    return null;
  }

  public getWorkThumbnail(project: Project): Observable<string | null> {
    return this.storageService.getProjectWorkThumbnailImageURL(project.shortName).pipe(
      catchError(_ => of(null)),
      map(value => value)
    )
  }

  // MARK: - Private

  private fetchImageURLsIfNeeded(projects: Project[]) {
    projects
      .filter(project => project.imageURL == '' || !isDef(project.imageURL))
      .forEach(project => {
        this.storageService.fetchProjectImageURL(project.linkName).subscribe({
          next: imageURL => {
            project.imageURL = imageURL;
            this.updateProject(project).then(() => {
              console.log('Projected updated for image url: ', project.name);
            });
          },
          error: error =>  {
            console.error('Project Image URL could not be found in database for project:', project.linkName, error);
            project.imageURL = '../assets/images/project-404.svg';
          }
        });
      });
  }
}
