import { Injectable } from '@angular/core';
import { DocumentReference } from '@angular/fire/compat/firestore';
import { getFirestore, doc, getDoc, setDoc, Firestore } from "firebase/firestore"
import { isProduction } from 'src/app/core/common.utils';
import { CVInformation } from 'src/app/model/FbDatabase/cv-information.model';

@Injectable({
  providedIn: 'root'
})
export class FirebaseFirestoreDatabaseService {

  constructor(
    private fbDatabase: Firestore
  ) {
  }

  // Public

  async getCVInformation(): Promise<CVInformation | undefined> {
    try {
      return (await getDoc(this.cvDocReference())).data() as CVInformation
    } catch (error) {
      console.error('getCVInformation() failed: ', error)
      return undefined
    }
  }

  async updateCVInformation(cvInformation: CVInformation): Promise<void> {
    try {
      await setDoc(this.cvDocReference(), cvInformation)
    } catch { error => {
      console.error('updateCVInformation() failed: ', error)
      throw error
    }}
  }

  // Private

  private filesFolderName = isProduction() ? 'files' : 'files-dev'

  private cvDocReference() {
    return doc(this.fbDatabase, this.filesFolderName, 'cv')
  }
}
