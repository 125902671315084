export class Client {
  shortname: string;
  name: string;
  url?: string;
  corporateColorPrimary?: string;
  corporateColorSecundary?: string;
  clientLogoFiletype: string;

  constructor(
    shortname: string,
    name: string,
    url: string = null,
    corporateColorPrimary: string = null,
    corporateColorSecundary: string = null,
    clientLogoFiletype: string = 'svg'
  ) {
    this.shortname = shortname;
    this.name = name;
    this.url = url;
    this.corporateColorPrimary = corporateColorPrimary;
    this.corporateColorSecundary = corporateColorSecundary;
    this.clientLogoFiletype = clientLogoFiletype;
  }
}
