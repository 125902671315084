import { Client } from './../../model/client.model';

export const clients: Client[] = [
  new Client('allianz', 'Allianz Private Krankenversicherung AG'),
  new Client('tsystems',  'T-Systems International GmbH'),
  new Client('computerfutures',  'Computer Futures, a trading division of SThree GmbH'),
  new Client('tk',  'Techniker Krankenkasse'),
  new Client('ibm',  'IBM Deutschland GmbH'),
  new Client('bitmarck',  'BITMARCK Technik GmbH'),
  new Client('dak',  'DAK-Gesundheit'),
  new Client('sap',  'SAP SE'),
  new Client('rki',  'Robert Koch-Institut'),
  new Client('dhl', 'DHL Paket GmbH'),
  new Client('elevatex', 'Elevatex GmbH', 'https://elevatex.de', null, null, 'png'),
  new Client('comdirect', 'Comdirect Bank AG', 'https://comdirect.de'),
  new Client('commerzbank', 'Commerzbank AG', 'https://commerzbank.de'),
];
