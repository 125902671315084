import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FirebaseFirestoreDatabaseService } from '../../service/firebase-firestore-database.service';
import { Observable, from, tap } from 'rxjs';
import { CVInformation } from 'src/app/model/FbDatabase/cv-information.model';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { jackInTheBoxOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-cv-download-button',
  standalone: false,
  templateUrl: './cv-download-button.component.html',
  styleUrl: './cv-download-button.component.scss',
  animations: [
    jackInTheBoxOnEnterAnimation(),
  ]
})
export class CvDownloadButtonComponent implements OnInit {

  @Input() title: string = 'CV - Felix Schmidt'
  @Input() btnClass: string = 'btn btn-light btn-fs-large'
  @Input() showIcon = true

  @Output() cvInformationAvailable = new EventEmitter<CVInformation>()

  cvInformation$: Observable<CVInformation | undefined>

  icons = {
    fileAlt: faFileAlt
  }

  ngOnInit(): void {
      this.cvInformation$ = from(this.fbDatabaseService.getCVInformation()).pipe(
        tap(cvInformation => {
          this.cvInformationAvailable.emit(cvInformation)
        })
      )
  }

  openCV(cvInformation: CVInformation) {
    if (cvInformation?.downloadURL) window.open(cvInformation.downloadURL, '_blank')
  }

  private fbDatabaseService = inject(FirebaseFirestoreDatabaseService)
}
