import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private localStorageSubject = new Subject<string>();

  constructor() { }

  watchLocalStorage(): Observable<any> {
    return this.localStorageSubject.asObservable();
  }

  setItem(key: string, data: any) {
    localStorage.setItem(key, data);
    this.localStorageSubject.next('changed');
  }

  getItem(key: string) {
    return localStorage.getItem(key);
  }

  removeItem(key) {
    localStorage.removeItem(key);
    this.localStorageSubject.next('changed');
  }
}
