export interface Testimonial {
  author: string;
  jobRole: string;
  company: string;
  profileUrl?: string;
  imageSrc: string;
  imageAlt: string;
  imageTitle: string;
  textHighlight: string;
  text: string;
  showFullText?: boolean;
}

export interface ProjectThumbnail extends Project {
  canBeDisplayed: boolean;
  hovered?: boolean;
}

export enum KindOfProduct {
  iOS = 'iOS', Web = 'Web'
}

export interface ProjectMoreInformation {
  title: string;
  url: string;
}

export class Project {
  constructor(
    public shortName: string = '',
    public name: string = '',
    public linkName: string = '',
    public kind: KindOfProduct = KindOfProduct.iOS,
    public client: string = '',
    public start: string = '',
    public end: string = '',
    public description: string = '',
    public descriptionTechnical: string = '',
    public moreInformations?: ProjectMoreInformation,
    public customProjectSite?: string,
    public role: string = '',
    public tags: string[] = [],
    public industry: string = '',
    public imageURL?: string,
    public workItemThumbnailImageURL?: string
  ) { }
}
