import { faLinkedinIn, faXing, faTwitter,  } from '@fortawesome/free-brands-svg-icons';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { Component, OnInit } from '@angular/core';
import { CVInformation } from '../model/FbDatabase/cv-information.model';
import { FirebaseFirestoreDatabaseService } from '../shared/service/firebase-firestore-database.service';
import { Observable, from } from 'rxjs';
import { jackInTheBoxOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  animations: [
    jackInTheBoxOnEnterAnimation(),
  ]
})
export class AboutComponent implements OnInit {

  cvInformation$: Observable<CVInformation | undefined>

  icons = {
    linkedinIn: faLinkedinIn,
    xing: faXing,
    twitter: faTwitter,
    fileDownload: faFileDownload,
  };

  constructor(
    private fbDatabaseService: FirebaseFirestoreDatabaseService
    ) { }

  ngOnInit() {
    this.cvInformation$ = from(this.fbDatabaseService.getCVInformation())
  }

  openCV(cvInformation: CVInformation) {
    if (cvInformation?.downloadURL) window.open(cvInformation.downloadURL, '_blank')
  }

}
