<section class="container
                my-7">
  <div class="row">

    <div class="col-12 col-lg-6
                order-last order-lg-first
                d-flex flex-column
                justify-content-center align-items-center
                slide-in-from-left">
      <div class="pt-6 pt-lg-0">
        <h1 class="display-4 text-dark"><strong class="fw-bold">About</strong> Felix.</h1>
        <p class="lead mt-1">Freelance software engineer from Dresden in Germany, but available worldwide.</p>

        <div class="pt-3">
          <p>Felix Schmidt is a highly experienced developer, with strong skills in mobile development for iOS and Web. Combined with enthusiasm for great UX design, attention to detail and a long history successfully published app projects for millions of users worldwide.</p>
          <p>A Business Computer Science graduate with more than 15 years of professional experience to help you build great digital products.</p>
        </div>

        <div class="pt-4">
          <p>Please find next my <strong>CV</strong> to download.</p>
          <app-cv-download-button [btnClass]="'btn btn-dark btn-fs-large'"></app-cv-download-button>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-6
                order-first order-lg-last
                slide-in-from-right">
      <img id="hero-image"
           src="../../assets/images/felix-schmidt-smiling-during-programming-an-ios-application.webp"
           alt="Felix Schmidt is smiling while iOS development" />
    </div>
  </div>
</section>

<section id="skills"
         class="inner-shadow
                bg-elevated-1-light
                py-7">
  <div class="text-center pb-4">
    <h2 class="display-5 fw-bold text-center">Skill Focus</h2>
    <p class="lead">Let's merge creativity with technology, <br>to craft your next digital solution.</p>
  </div>
  <div class="container">
    <div class="row">

      <div class="col-12 col-lg-4
                  order-2 order-lg-1">
        <h3 class="skill-focus">Developer</h3>
        <h4 class="skill-sub-focus">iOS</h4>
        <ul class="fs-list">
          <li>Native iOS development in Swift and Xcode</li>
          <li>Reactive programming</li>
          <li>Using SwiftUI, UIKit, Combine, RxSwift, WatchKit, Alamofire and so much more</li>
          <li>Apply MVVM, MVVM-C, MVP</li>
          <li>Unit and UI testing</li>
          <li>RESTful API design</li>
          <li>Accessibility</li>
          <li>Build CI/CD environments, for e.g. with Gitlab</li>
          <li>... Carry an Apple MacBook Pro with me 💻</li>
        </ul>
        <h4 class="skill-sub-focus">Web</h4>
        <ul class="fs-list">
          <li>Web development in Angular</li>
          <li>Programming in Typescript, Javascript</li>
          <li>Markup in HTML3 and CSS3</li>
          <li>RxJS, Angular Material, SASS and so much more</li>
          <li>Implement Firebase backends</li>
          <li>... Eating Muesli in front of Chrome DevTools 🥣</li>
        </ul>
      </div>

      <div class="col-12 col-lg-4
                  order-1 order-lg-2
                  d-flex
                  justify-content-center align-items-center
                  py-4">
        <img class="skill-focus-pie-chart"
             src="../../assets/images/skill-focus-pie-chart.svg"
             alt="Skill focus as a pie chart">
      </div>

      <div class="col-12 col-lg-4
                  order-3">
        <h3 class="skill-focus">Creative Mind & Designer</h3>
        <ul class="fs-list
                   mt-3">
          <li>Design Thinking</li>
          <li>UX Design</li>
          <li>Creative design collaboration flows with Sketch Tool, Zeplin and so much more</li>
          <li>Prototyping and Usability evaluations</li>
          <li>Vector graphic design</li>
          <li>... Drinking hot coffee with organic milk ☕️</li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section id="random-facts"
         class="inner-shadow
                fscolor-bg-elevated-3-light
                py-7">
  <div class="container">
    <div class="row">

      <div class="col-xs-12 col-lg-6
                  d-flex flex-column
                  justify-content-center align-items-center">
        <img class="rounded"
             src="../../assets/images/felix-schmidt-smiling-with-cup-behind-macbook.webp"
             loading="lazy"
             alt="Felix Schmidt is programming Swift and thinking with a coffee in his hands about a solution for the problem." />
      </div>

      <div class="col-xs-12 col-lg-6
                  pt-5
                  d-flex flex-column
                  justify-content-center align-items-stretch align-items-sm-center">
        <article class="text-center text-lg-left">
          <h2 class="text-dark">Seven<span class="fw-bold"> random facts</span></h2>
          <ul class="fs-list text-dark">
            <li>I love clean code.</li>
            <li>I love awesome designs.</li>
            <li>I love products from Apple (suprise!).</li>
            <li>I'm a bit of an order freak.</li>
            <li>I drink too much coffee while working.</li>
            <li>I love to laugh and make jokes.</li>
            <li>I'm grateful to learn from others.</li>
          </ul>
        </article>
      </div>
    </div>
  </div>
</section>

<app-what-do-next [options]="{work: true, contact: true, socialConnect: true}"></app-what-do-next>
