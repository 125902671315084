import { isProduction } from 'src/app/core/common.utils';
import { Observable, finalize, from, lastValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FirebaseFirestoreDatabaseService } from './firebase-firestore-database.service';
import { CVLangLocale } from 'src/app/model/FbDatabase/cv-information.model';

@Injectable({
  providedIn: 'root'
})
export class FirebaseStorageService {

  private projectImagesPath = isProduction() ? 'projects/' : 'projects-dev/';
  private workItemThumbnailFolder = 'work-item-thumbnails/';
  private filesFolder = isProduction() ? 'files' : 'files-dev';

  cvUploadProgress$: Observable<number | null> = null;

  constructor(
    private fbStorage: AngularFireStorage,
    private fbDatabaseService: FirebaseFirestoreDatabaseService,
  ) {}

  /**
   * Stores a project image
   * @param imageName should be project link name
   * @param image should be SVG type
   */
  public storeProjectImage(imageName: string, image: any) {
    const fileExtension = image.name.split('.').pop();
    return this.fbStorage.upload(
      this.projectImagesPath + imageName + '.' + fileExtension,
      image
    );
  }

  /**
   * Returns the image URL from the given project link name
   * @param projectLinkName project link name
   */
  public fetchProjectImageURL(projectLinkName: string): Observable<any> {
    const fileName = projectLinkName + '.svg';
    return this.fbStorage.ref(this.projectImagesPath + fileName).getDownloadURL();
  }

  public getProjectWorkThumbnailImageURL(projectShortName: string) {
    const fileName = 'work-thumbnail-' + projectShortName + '@0.5x.webp';
    return this.fbStorage
      .ref(this.projectImagesPath + this.workItemThumbnailFolder + fileName)
      .getDownloadURL();
  }

  uploadCV(cvFile: File): Promise<{downloadURL: string}> {
    return new Promise((resolve, reject) => {
      const cvFileVersion = this.extractGermanDateStringFromFilename(cvFile.name)
      const cvVersion = cvFileVersion ? cvFileVersion : 'unknown'

      const cvFileLangLocale = this.extractLangLocaleFromFilename(cvFile.name)
      console.log(cvFileLangLocale)
      const dashLangIfAvailable = cvFileLangLocale.language ? `-${cvFileLangLocale.language}` : ''

      const filePath = `${this.filesFolder}/cv/${cvFileLangLocale.locale}/Felix-Schmidt-iOS-Engineer-Freelance-CV${dashLangIfAvailable}.pdf`
      const fileRef = this.fbStorage.ref(filePath)

      const storageUploadTask = this.fbStorage.upload(
        filePath,
        cvFile,
        {
          contentType: 'application/pdf',
          customMetadata: {
            copyright: `Copyright © ${new Date().getFullYear()} Felix Schmidt Software Engineering, represented by Felix Schmidt`
          }
        },
      )

      this.cvUploadProgress$ = from(storageUploadTask.percentageChanges())

      storageUploadTask.snapshotChanges()
        .pipe(
          finalize(async () => {
            const downloadURL = await lastValueFrom(fileRef.getDownloadURL())

            // Save storage information into database
            this.fbDatabaseService.updateCVInformation({
              language: cvFileLangLocale.language,
              locale: cvFileLangLocale.locale,
              version: cvVersion,
              downloadURL: downloadURL,
            })
            .then(() => resolve({ downloadURL: downloadURL }))
            .catch((error) => reject(error))
            .finally(this.cvUploadProgress$ = null)
          })
        )
        .subscribe()
    })
  }

  private extractLangLocaleFromFilename(filename): CVLangLocale | null {
    // Example
    // Filename: Felix-Schmidt-CV-Frontend-Freelance-en-DE-20250203.pdf
    // en-DE = english lang, german locale (market)

    const langAndLocaleMatch = filename.match(/\b[a-z]{2}\-[A-Z]{2}\b/)
    const langMatch = langAndLocaleMatch[0].match(/^[a-z]{2}/)
    const localeMatch = langAndLocaleMatch[0].match(/[A-Z]{2}$/)

    console.log('CV language: ', langMatch)
    console.log('CV locale: ', localeMatch)

    const cvLangLocale: CVLangLocale = {}

    if(langMatch) {
      const langStr = langMatch[0]
      const allowedLanguages = ['en', 'de']
      cvLangLocale.language = allowedLanguages.includes(langStr) ? langStr : null
    }

    if (localeMatch) {
      const localeStr = localeMatch[0]
      const allowedLocales = ['CH', 'DE']
      cvLangLocale.locale = allowedLocales.includes(localeStr) ? localeStr : null
    }

    return cvLangLocale
  }

  private extractGermanDateStringFromFilename(filename: string): string | null {
    const match = filename.match(/-(\d{8}).pdf$/)
    if (match && match[1]) {
      const dateStr = match[1]
      const year = dateStr.slice(0, 4)
      const month = dateStr.slice(4, 6)
      const day = dateStr.slice(6, 8)

      return `${day}.${month}.${year}`
    }
    return null
  }
}

/**
 * # Project Images
 *
 * ## Work Project Promo
 * projects[-dev]/<linkName>/work-promo-<shortName>-large@2x.webp
 * Example
 * projects/corona-warn-app/work-promo-cwa-large@2x.webp
 *
 * ## Project Thumbnail
 * projects[-dev]/<linkName>/project-thumbnail-<shortName>.svg
 * Example
 * projects-dev/pillenfee/project-thumbnail-pil.svg
 *
 * ## Project Previews
 * projects[-dev]/<linkName>/project-preview-<shortName>-<##>.webp
 * Example
 * projects-dev/tk-safe/project-preview-ega-04.webp
 */
