import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-angular-environment',
  templateUrl: './angular-environment.component.html',
  styleUrls: ['./angular-environment.component.scss']
})
export class AngularEnvironmentComponent {
  environment = environment
  objectKeys = Object.keys
}
