<div class="container">
  <div class="row">
    <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center">
      <div class="text-centered-desktop-left">
        <h1 class="fs-headline">{{ 'contact.HEADLINE' | translate }}</h1>
        <p class="lead mb-4" [innerHTML]="'contact.LEAD' | translate"></p>

        <div class="d-flex justify-content-center justify-content-lg-start align-items-center gap-3">
          <fa-icon [icon]="icons.phone" [size]="'2x'"></fa-icon>

          <div class="d-flex flex-column gap-1">
            <a href="tel:+41784443944"
               [title]="'contact.PHONE_TITLE' | translate"
               [attr.aria-label]="'contact.PHONE_ARIA_LABEL' | translate">
              {{ 'contact.PHONE_CH' | translate }}
            </a>
            <a href="tel:+491734172334"
               [title]="'contact.PHONE_TITLE' | translate"
               [attr.aria-label]="'contact.PHONE_ARIA_LABEL' | translate">
              {{ 'contact.PHONE_DE' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center">
      <img class="rounded-image rounded-circle"
           src="../../assets/images/felix-schmidt-with-iphone-and-macbook-smiles-before-camin.webp"
           alt="Felix Schmidt with Apple iPhone and Apple MacBook smiles in front of a fireplace." />
    </div>
  </div>
</div>

<div class="container-fluid inner-shadow bg-elevated-1-light p-5">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center gap-3">
        <fa-icon [icon]="icons.paperPlane" [size]="'3x'"></fa-icon>
        <h2>{{ 'contact.EMAIL_TITLE' | translate }}</h2>
      </div>
      <div class="col-12 col-lg-6">
        <form class="d-flex flex-column justify-content-between align-items-stretch gap-3"
              [formGroup]="contactForm"
              (ngSubmit)="onSubmit()">

          <div class="form-group">
            <label class="form-label small required">{{ 'contact.FORM.NAME_LABEL' | translate }}</label>
            <input type="text"
                   class="form-control"
                   formControlName="full_name"
                   [placeholder]="'contact.FORM.NAME_PLACEHOLDER' | translate" />
          </div>

          <div class="form-group">
            <label class="form-label small required">{{ 'contact.FORM.EMAIL_LABEL' | translate }}</label>
            <input type="email"
                   class="form-control"
                   formControlName="email"
                   [placeholder]="'contact.FORM.EMAIL_PLACEHOLDER' | translate"
                   [class]="{'is-invalid': contactForm.controls.email.invalid && (contactForm.controls.email.dirty || contactForm.controls.email.touched)}" />
          </div>

          <div class="form-group">
            <label class="form-label small required">{{ 'contact.FORM.PHONE_LABEL' | translate }}</label>
            <input type="tel"
                   class="form-control"
                   formControlName="phone_number"
                   [placeholder]="'contact.FORM.PHONE_PLACEHOLDER' | translate" />
          </div>

          <div class="form-group">
            <label class="form-label small">{{ 'contact.FORM.SERVICE_LABEL' | translate }}</label>
            <select class="form-control"
                    formControlName="purpose">
              <option *ngFor="let purpose of purposes">{{ purpose }}</option>
            </select>
          </div>

          <div class="form-group">
            <label class="form-label small">{{ 'contact.FORM.MESSAGE_LABEL' | translate }}</label>
            <textarea class="form-control"
                      formControlName="message"
                      rows="5"
                      [placeholder]="'contact.FORM.MESSAGE_PLACEHOLDER' | translate"></textarea>
          </div>

          <p class="mb-0"><small [innerHTML]="'contact.FORM.PRIVACY_POLICY' | translate"></small></p>

          <div class="d-flex flex-column align-items-center align-items-lg-start">
            <button [disabled]="!contactForm.valid"
                    type="submit"
                    class="btn btn-primary btn-fs-large float-right">
              <span [ngSwitch]="showLoadingIndicator">
                <span *ngSwitchCase="true">
                  <div class="spinner-grow spinner-grow-sm text-info"></div>
                  {{ 'contact.FORM.SUBMIT_BUTTON.LOADING' | translate }}
                </span>
                <span *ngSwitchCase="false">{{ 'contact.FORM.SUBMIT_BUTTON.DEFAULT' | translate }}</span>
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid inner-shadow office-background-image">
  <div class="container d-flex justify-content-center justify-content-md-end justify-content-lg-start align-items-center">
    <div class="row">
      <div class="col-12">
        <div class="card office-sisikon">
          <img src="../../assets/images/office-2.webp"
               alt="Office Desk Felix Schmidt for Software Development"
               class="card-img-top" />
          <div class="card-body">
            <h5 class="card-title">{{ 'contact.OFFICE.TITLE' | translate }}</h5>
            <h6 class="figure-caption">Felix Schmidt Software Engineering</h6>
            <address class="card-text" [innerHTML]="'contact.OFFICE.ADDRESS' | translate"></address>
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item py-3">
              <h5 class="h6">{{ 'contact.OFFICE.OPENING_TIMES' | translate }}</h5>
              <p>{{ 'contact.OFFICE.TIMES' | translate }}</p>
              <p class="mb-0"><small [innerHTML]="'contact.OFFICE.DOORBELL' | translate"></small></p>
            </li>
          </ul>
          <div class="card-body">
            <a href="https://maps.app.goo.gl/nB38gDEH6sqxnMTR9"
               [title]="'contact.OFFICE.ROUTE_TITLE' | translate"
               class="card-link">{{ 'contact.OFFICE.ROUTE_LINK' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid inner-shadow bg-elevated-2-light">
  <div class="container py-4">
    <div class="row">
      <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center align-items-lg-end">
        <img class="rounded-image rounded-circle"
             src="../../assets/images/felix-schmidt-is-calling-client-in-front-of-frauenkirche-1.webp"
             alt="Felix Schmidt is calling a client in front of the Frauenkirche in Dresden." />
      </div>

      <div class="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center align-items-lg-start gap-2">
        <div class="text-centered-desktop-left">
          <h3 class="h1">{{ 'contact.THANK_YOU.HEADLINE' | translate }}</h3>
          <p [innerHTML]="'contact.THANK_YOU.MESSAGE' | translate"></p>
          <p class="h1 handwritten">{{ 'contact.THANK_YOU.SIGNATURE' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal when submit contact form -->
<ng-template #modalSubmitFormWasFinished
             let-modalSubmitFormWasFinished
             id="modalSubmitFormWasFinished">
  <div class="modal-header">
    <h3 class="modal-title"
                id="modal-basic-title">{{ 'contact.MODAL.TITLE' | translate }}</h3>
    <button type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modalSubmitFormWasFinished.dismiss('Cross click')"></button>
  </div>

  <div class="modal-body">
    <p class="text-center"
       [innerHTML]="'contact.MODAL.MESSAGE' | translate">
    </p>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary btn-fs-medium"
            (click)="modalSubmitFormWasFinished.close()">Okay</button>
  </div>
</ng-template>
