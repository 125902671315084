<div class="py-4">
  <h3>CV Upload</h3>

  <p *ngIf="(cvInformation$ | async) as cvInformation" class="lead">
    Version stored:
    <ng-container [ngSwitch]="isUploadingCV">
      <ng-container *ngSwitchCase="true">
        <div class="spinner-grow spinner-grow-sm" role="status">
          <span class="sr-only">Uploading CV...</span>
        </div>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <code>{{ cvInformation.version}}</code>
      </ng-container>
    </ng-container>
  </p>

  <div class="mb-3">
    <label for="inputCVUpload" class="form-label">New CV</label>
    <div class="d-flex justify-content-begin align-items-center gap-3">
      <input
        class="form-control"
        type="file"
        id="inputCVUpload"
        (change)="uploadCV($event)"
      >
      <button
        *ngIf="(cvInformation$ | async) as cvInformation"
        [disabled]="isUploadingCV || !cvInformation.downloadURL"
        class="btn btn-small btn-info"
        (click)="openCV(cvInformation)"
      >
        <div class="d-flex justify-content-space align-items-center gap-2">
          <span *ngIf="( fbStorageService.cvUploadProgress$ | async ) as uploadProgress; else openCVTitle">
            {{ uploadProgress | number:'1.0-0' }}% Uploading
          </span>
          <ng-template #openCVTitle>
            <span>Open CV</span>
          </ng-template>
        </div>
      </button>
    </div>
  </div>

</div>
