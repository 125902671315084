<div class="container">
  <app-loading-indicator *ngIf="shouldShowLoadingSpinner"></app-loading-indicator>
</div>

<div *ngIf="project"
     [class]="shouldShowLoadingSpinner ? 'd-none' : 'fade-in duration-2'">
  <section class="container">
    <div class="row">
      <article class="col-12 col-md-6
                      d-flex flex-column
                      justify-content-center align-items-stretch
                      order-2 order-md-1
                      pb-5"
               [class]="shouldShowLoadingSpinner ? '' : 'slide-in-from-left'">

        <div class="d-flex
                    justify-content-between align-items-center
                    gap-3">
          <h1 class="product-name">{{ project.name }}</h1>

          <div class="project-kind
                      d-flex
                      justify-content-center align-items-center
                      bg-dark">{{ project.kind }}</div>
        </div>
        <hr>

        <div class="product-client-duration
                    d-flex
                    justify-content-between align-items-start
                    mb-3">
          <div>{{ project.client }}</div>

          <div class="w-40 text-right">{{ project.end ? '' : 'since ' }}{{ project.start | date:'MM/yyyy' }}{{ project.end ? (' - ' + (project.end | date:'MM/yyyy')) : '' }}</div>
        </div>

        <div class="d-flex
                    justify-content-start align-items-start
                    gap-2
                    mb-2">

          <div style="width: 100px">{{ 'projectDetails.ROLE' | translate }} </div><strong>{{ project.role }}</strong>
        </div>

        <div class="d-flex
                    justify-content-start align-items-start
                    gap-2
                    mb-8">

          <div style="width: 100px">{{ 'projectDetails.INDUSTRY' | translate }} </div><strong>{{ project.industry }}</strong>
        </div>
      </article>

      <!-- Project Image -->
      <article id="product-image-wrapper"
               class="col-12 col-md-6
                      order-1 order-md-2
                      d-flex
                      justify-content-start align-items-center"
               [class]="shouldShowLoadingSpinner ? '' : 'slide-in-from-right'">
        <img class="rounded"
             [src]="project.imageURL === '' ? '../assets/images/loading-spinner.gif' : project.imageURL" async
             (load)="imageWasLoaded()" />
      </article>
    </div>
  </section>

  <section id="product-details"
           class="inner-shadow bg-elevated-1-light py-7"
           [class]="shouldShowLoadingSpinner ? '' : 'slide-in-from-bottom'">

    <!-- MARK: Custom Project Desc -->
    <article *ngIf="project.customProjectSite"
             [innerHTML]="project.customProjectSite"></article>


    <!-- MARK: No Custom Project Desc -->
    <article *ngIf="!project.customProjectSite"
             class="container">
      <div *ngIf="project.description"
           class="d-flex flex-column justify-content-space align-items-start gap-5">

        <div>
          <h2>{{ 'projectDetails.ABOUT_PRODUCT' | translate }}</h2>
          <p [innerHTML]="project.description"></p>
        </div>


        <div *ngIf="project.moreInformations">
          <h3 class="h4">{{ 'projectDetails.MORE_INFORMATION' | translate }}</h3>
          <p>{{ 'projectDetails.MORE_INFORMATION_TEXT' | translate }}
            <a [href]="project.moreInformations.url"
               [title]="project.moreInformations.title"
               target="blank">
              {{ project.moreInformations.title }}
            </a>
          </p>
        </div>


        <div *ngIf="project.descriptionTechnical">
          <h2>{{ 'projectDetails.TECHNICAL_DESCRIPTION' | translate }}</h2>
          <p [innerHTML]="project.descriptionTechnical"></p>
        </div>


        <div class="d-flex flex-wrap justify-content-start align-items-start gap-3">
          <div *ngFor="let tag of project.tags"
               class="product-tag bg-stone-500 text-light">
            {{ tag }}
          </div>
        </div>

      </div>
    </article>
  </section>

  <app-what-do-next [options]="{work: true, contact: true, socialConnect: true}"></app-what-do-next>

  <div class="inner-shadow fscolor-bg-elevated-2-light pt-32 pb-32">
    <div class="container">
      <div class="d-flex
                  justify-content-start align-items-start
                  gap-1">
        <p>*</p>
        <p><small>{{ 'projectDetails.DISCLAIMER' | translate }}</small></p>
      </div>
    </div>
  </div>

  <a title="{{ 'projectDetails.NEXT_PROJECT' | translate }}"
     class="project-navigation
            next
            d-flex
            justify-content-center align-items-center"
     (click)="showNextProject(project)"
     *ngIf="nextProject(project)">
    <fa-icon [icon]="icons.arrowLeft"></fa-icon>
  </a>

  <a title="{{ 'projectDetails.PREVIOUS_PROJECT' | translate }}"
     class="project-navigation
            previous
            d-flex
            justify-content-center align-items-center"
     (click)="showPreviousProject(project)"
     *ngIf="previousProject(project)">
    <fa-icon [icon]="icons.arrowRight"></fa-icon>
  </a>
</div>
