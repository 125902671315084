
<div class="container">
  <h1 class="text-center my-4">Dashboard</h1>

  <section class="dashboard-section">
    <div class="d-flex justify-content-between align-items-center">
      <h3>Projects</h3>
      <code>{{ appEnvironmentText }}</code>
    </div>

    @if (projects.length === 0) {
      <app-loading-indicator></app-loading-indicator>
    } @else if (projects) {
      <table class="table table-striped">
        <thead>
          <tr>
            @for (col of ['Zeitraum', 'Name', 'Kind', '']; track col) {
              <th scope="col">{{ col }}</th>
            }
          </tr>
        </thead>

        <tbody>
        @for(project of projectService.sortProjects(projects); track project.shortName) {
          <tr>
            <th scope="row">{{ project.start | date:'MM/yyyy' }} - {{ project.end | date:'MM/yyyy' }}</th>

            <td class="d-flex justify-content-start align-items-center gap-2">
              <img [src]="project.imageURL"
                   async
                   class="rounded mr-2"
                   style="width: 40px"
              />
              <div>{{ project.name }}</div>
            </td>

            <td>{{ project.kind }}</td>

            <td>
              <app-add-or-edit-project [projectToEdit]="project" [modus]="'edit'"></app-add-or-edit-project>
            </td>
          </tr>
        }
        </tbody>
      </table>
    } @else {
      <p class="text-danger text-center">Oops, projects could not be loaded.</p>
    }

    <app-add-or-edit-project [modus]="'add'"></app-add-or-edit-project>
  </section>

  <section class="dashboard-section">
    <h3>Individuelle Projektbeschreibung</h3>
    <p class="lead">Editiere das Markup der folgenden Seite, um eine individuelle Beschreibung für ein Projekt anzulegen. Das Markup muss dann im Firestore als z.B. Technische Beschreibung gespeichert werden.</p>
    <a href="dashboard/custom-project-site">Seite anschauen</a>
  </section>

  <section class="dashboard-section">
    <app-cv-upload></app-cv-upload>
  </section>

  <section class="dashboard-section">
    <app-angular-environment></app-angular-environment>
  </section>
</div>
