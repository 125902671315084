import { faLinkedinIn, faXing, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CvDownloadButtonComponent } from '../shared/view/cv-download-button/cv-download-button.component';
import { WhatDoNextComponent } from '../shared/view/what-do-next/what-do-next.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, CvDownloadButtonComponent, WhatDoNextComponent, FontAwesomeModule]
})
export class AboutComponent {

  icons = {
    linkedinIn: faLinkedinIn,
    xing: faXing,
    twitter: faTwitter,
    fileDownload: faFileDownload,
  };

  constructor() { }
}
