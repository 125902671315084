import { isDef } from 'src/app/core/common.utils';
import { Router } from '@angular/router';
import { ProjectService } from './../shared/service/project.service';
import { Project, ProjectThumbnail } from './../model/model.vo';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { from, mergeMap, map } from 'rxjs';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss']
})
export class WorkComponent implements OnInit {

  isLoading = false;
  isError = false;

  public projects: ProjectThumbnail[] = [];

  constructor(
    private router: Router,
    private projectService: ProjectService
  ) {}

  ngOnInit() {
    this.loadProjects();
  }

  showProjectDetails(project: Project) {
    this.router.navigate(['/work', project.linkName]);
  }

  loadProjects(): void {
    this.isLoading = true
    this.isError = false;

    this.projectService.projects$.subscribe({
      next: projects => {
        const projectsSorted = this.projectService.sortProjects(projects);

        from(projectsSorted)
        .pipe(
          mergeMap((project, index) => this.projectService.getWorkThumbnail(project)
          .pipe(
            // Map to url and index can be returned combined as object
            map(workThumbnailUrl => {
              return {
                index: index,
                url: workThumbnailUrl
              }
            })
          ), 1) // max 1 concurrent request
        )
        .subscribe({
          next: urlAndIndex => {

            // When first url emitted, hide loading indicator
            this.isLoading = false;

            // With every work thumbnail emitted, push the corresponding project to the array with url replaced/included.
            // Then the view will show them one by one ...
            const projectToPush = projectsSorted[urlAndIndex.index]
            this.projects.push({
              ...projectToPush,
              workItemThumbnailImageURL: urlAndIndex.url || this.getFallbackWorkItemThumbnailImage(projectToPush),
              canBeDisplayed: false,
              hovered: false,
            })
          },
          error: () => this.onError()
        });
      },
      error: () => this.onError()
    });
  }

  private getFallbackWorkItemThumbnailImage(project: Project): string {
    return isDef(project.imageURL) ? project.imageURL : '../assets/images/project-404.svg';
  }

  private onError(): void {
    this.isLoading = false
    this.isError = true
  }
}
