@if (cvInformation$ | async; as cvInformation) {
  <button
    [class]="btnClass"
    (click)="openCV(cvInformation)"
    [@jackInTheBoxOnEnter]
  >
  @if (showIcon) {
    <fa-icon [icon]="icons.fileAlt"
             class="me-2">
    </fa-icon>
  }
    {{ title }}
  </button>
}
