<div class="mx-0 mx-lg-7">
  <ngb-carousel
    #carousel
    [interval]="8000"
    [pauseOnHover]="carouselConfig.pauseOnHover"
    [pauseOnFocus]="carouselConfig.pauseOnFocus"
    (slideEvent)="onSlide($event)"
    class="mb-4"
  >
    @for (testimonial of testimonials; track testimonial) {
      <ng-template ngbSlide>
        <div class="slide-wrapper rounded bg-elevated-1-light shadow-sm d-flex flex-column flex-md-row justify-content-around align-items-center align-items-md-start gap-5 m-2 m-lg-5 p-5 p-md-7">
          <img [src]="testimonial.imageSrc"
                   [alt]="testimonial.imageAlt"
                   [title]="testimonial.imageTitle"
                   class="rounded-circle border" />

          <div class="quote-wrapper">
            <blockquote class="highlight">
              <span class="handwritten">
                {{testimonial.textHighlight }}
              </span>
            </blockquote>

            <blockquote class="mb-4">
              <span [class.no-line-clamp]="testimonial.showFullText"
                    [class.line-clamp-3]="!testimonial.showFullText">
                {{testimonial.text }}
              </span>
              <button class="btn btn-link p-0"
                      [class.d-none]="testimonial.showFullText"
                      (click)="testimonial.showFullText = true">
                read more
              </button>
            </blockquote>

            <div>
              @if (testimonial.profileUrl) {
                <a [href]="testimonial.profileUrl"
                   target="blank"
                   class="recommender">
                   <strong>{{testimonial.author}} </strong>
                </a>
              } @else {
                <strong>{{testimonial.author}}</strong>
              }
            </div>
            <div>
              <small>{{testimonial.jobRole}}, {{testimonial.company}}</small>
            </div>
          </div>
        </div>
      </ng-template>
    }
  </ngb-carousel>
</div>
