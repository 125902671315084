import { Component, OnInit } from '@angular/core';
import { Observable, from } from 'rxjs';
import { CVInformation } from 'src/app/model/FbDatabase/cv-information.model';
import { FirebaseFirestoreDatabaseService } from 'src/app/shared/service/firebase-firestore-database.service';
import { FirebaseStorageService } from 'src/app/shared/service/firebase-storage.service';

@Component({
  selector: 'app-cv-upload',
  templateUrl: './cv-upload.component.html',
  styleUrls: ['./cv-upload.component.scss']
})
export class CvUploadComponent implements OnInit {

  cvInformation$: Observable<CVInformation | undefined>;
  isUploadingCV = false;

  constructor(
    protected fbStorageService: FirebaseStorageService,
    private fbDatabaseService: FirebaseFirestoreDatabaseService
  ) {}

  ngOnInit(): void {
    this.updateCVInformation()
  }

  protected openCV(cvInformation: CVInformation): void {
    if (cvInformation?.downloadURL) {
      window.open(cvInformation.downloadURL, '_blank')
    }
  }

  protected uploadCV(event: Event): void {
    const inputElement = event.target as HTMLInputElement
    const file: File = (inputElement.files as FileList)[0]

    this.isUploadingCV = true;
    this.fbStorageService.uploadCV(file)
      .finally(() => {
        this.updateCVInformation()
        this.isUploadingCV = false
        this.clearInputElement(inputElement)
      })
      .then(result => {
        console.log('CV Upload successful: ', result.downloadURL)
      })
      .catch(error => console.error('Upload CV failed: ', error));
  }

  private updateCVInformation(): void {
    this.cvInformation$ = from(this.fbDatabaseService.getCVInformation())
  }

  private clearInputElement(inputElement: HTMLInputElement): void {
    inputElement.value = '';
  }
}
