<header class="landing container-fluid d-flex justify-content-center align-items-center p-5">
  <div class="container d-flex flex-column justify-content-center align-items-center align-items-md-start gap-6 gap-md-6">

    <!-- Texts -->
    <div class="container-texts d-flex flex-column justify-content-space align-items-start gap-2 gap-lg-4">

      <div class="d-flex flex-column justify-content-space align-items-start gap-2">
        <code class="h4 d-none d-lg-block text-primary">{{'{'}} codeMeetsComplexity {{ '}()' }}</code>
        <h1 class="text-dark title" [innerHTML]="( 'start.hero.TITLE' | translate )"></h1>
      </div>

      <p class="lead text-dark subtitle d-none d-lg-block" [innerHTML]="'start.hero.SUBTITLE_DESKTOP' | translate"></p>
      <p class="lead text-dark subtitle d-lg-none" [innerHTML]="'start.hero.SUBTITLE_MOBILE' | translate"></p>
    </div>

    <!-- Buttons -->
    <div class="d-flex flex-column flex-sm-row gap-3">
      <button class="btn btn-primary btn-fs-large"
              type="button"
              routerLink="/contact">
        {{ 'start.hero.BTN_PRIMARY_TITLE' | translate }}
      </button>
      <button class="btn btn-outline-light btn-fs-large"
              type="button"
              routerLink="/work">
        {{ 'start.hero.BTN_SECONDARY_TITLE' | translate }}
      </button>
    </div>
  </div>
</header>

<section class="bg-light inner-shadow">
  <div class="container py-7">
    <div class="row justify-content-center">
      <div class="col-12 col-md-10">
        <div class="text-center mb-5">
          <h2 class="display-5 text-dark" [innerHTML]="'start.clients.HEADLINE' | translate"></h2>
          <p #clientsLead class="lead mt-3" [innerHTML]="'start.clients.LEAD' | translate"></p>
        </div>

        @defer (on viewport(clientsLead)) {
          <app-client-logos></app-client-logos>
        }

        <div [animateIfInViewport]="{ style: 'fadeInUp' }"
             class="d-flex flex-column justify-content-space align-items-center gap-4 mt-7">
          <h3 class="h5 text-center" [innerHTML]="'start.clients.BE_INVITED' | translate"></h3>
          <button class="btn btn-grad-dark btn-fs-large"
                  type="button"
                  routerLink="/contact">
            {{ 'start.clients.CTA' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="py-7 inner-shadow">
  <h2 [animateIfInViewport]="{ style: 'slideInDown' }" class="display-5 text-center text-dark mt-7"><strong class="fw-bold">{{ 'start.servicesPromo.HEADLINE' | translate }}</strong></h2>
  <p [animateIfInViewport]="{ style: 'slideInUp' }" class="display-3 text-center text-dark" [innerHTML]="'start.servicesPromo.SUBHEADLINE' | translate"></p>
</section>

<app-promo-item [model]="servicePromoItemModels.iOS"></app-promo-item>
<app-promo-items [models]="[servicePromoItemModels.web, servicePromoItemModels.design]"></app-promo-items>

<section class="d-flex flex-column justify-content-center align-items-center gap-4 py-7">
  <h2 animateIfInViewport="slideInDown"
      class="display-5 text-center text-dark"
      [innerHTML]="'start.letsTalkAbout.HEADLINE' | translate">
  </h2>

  <button class="btn btn-grad-dark btn-fs-large"
          type="button"
          animateIfInViewport="slideInDown"
          routerLink="/contact">
    {{ 'start.letsTalkAbout.CTA' | translate }}
  </button>
</section>

<section class="bg-dark">
  <div class="container py-7 d-flex flex-column justify-content-center align-items-center">
    <div class="text-center mb-5">
      <h2 class="display-5 text-white" [innerHTML]="'start.forRecruiters.HEADLINE' | translate"></h2>
      <p #forRecruitersLead class="lead text-secondary" [innerHTML]="'start.forRecruiters.LEAD' | translate"></p>
    </div>
    @defer (on viewport(forRecruitersLead); prefetch on idle) {
      <app-cv-download-button></app-cv-download-button>
    }
  </div>
</section>

<section id="testimonials" class="container-fluid topic inner-shadow">
  <article class="container">
      <h2 class="display-5 text-center text-dark" [innerHTML]="'start.testimonials.HEADLINE' | translate"></h2>
      <p class="lead text-center" [innerHTML]="'start.testimonials.LEAD' | translate"></p>
      <app-testimonials></app-testimonials>
  </article>
</section>

<section id="about" class="container-fluid topic inner-shadow">
  <div class="container">
    <h2 [innerHTML]="'start.aboutMeIn60Seconds.HEADLINE' | translate"></h2>
    <article class="row">
      <!-- Career Highlights -->
      <div class="col-xs-12 col-md-6">
        <h3 [innerHTML]="'start.aboutMeIn60Seconds.SUBLINE_CAREER_HIGHLIGHTS' | translate"></h3>
        <ul class="facts">
          <li [innerHTML]="'start.aboutMeIn60Seconds.CAREER_1' | translate"></li>
          <li [innerHTML]="'start.aboutMeIn60Seconds.CAREER_2' | translate"></li>
          <li [innerHTML]="'start.aboutMeIn60Seconds.CAREER_3' | translate"></li>
        </ul>
      </div>

      <!-- Fun Facts -->
      <div class="col-xs-12 col-md-6">
        <div class="headline-with-button">
          <h3 class="headline">{{ aboutMeFunFacts.length }} {{ 'start.aboutMeIn60Seconds.SUBLINE_FUN_FACTS' | translate }}</h3>
          <button class="btn btn-fs-small"
                  (click)="shakeFunFacts()">surprise me</button>
        </div>
        <ul class="facts">
          <li *ngFor="let funFact of aboutMeFunFacts">{{ funFact }}</li>
        </ul>
      </div>
    </article>
  </div>
</section>

<app-what-do-next [options]="{about: true, work: true, contact: true, socialConnect: true}"></app-what-do-next>
