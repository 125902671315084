import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PromoItemModel } from 'src/app/model/model.vo';

@Component({
  selector: 'app-promo-item',
  templateUrl: './promo-item.component.html',
  styleUrls: ['./promo-item.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class PromoItemComponent {
  @Input('model') model: PromoItemModel;
}
