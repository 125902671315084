import { referrals, ReferralsDataSource } from './../shared/data/referrals.data';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { faLinkedinIn, faXing, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, FontAwesomeModule, TranslateModule]
})
export class NavComponent implements OnInit {

  public socialMediaIcons = {
    github: faGithub,
    linkedIn: faLinkedinIn,
    xing: faXing,
    externalLink: faExternalLinkAlt
  };

  public toggleNav = false;

  public referrals: ReferralsDataSource

  constructor(public authService: AuthService) { }

  ngOnInit() {
    this.referrals = referrals;
  }

  toggleNavigation() {
    this.toggleNav = !this.toggleNav;
    if (this.toggleNav) {
      this.disableScroll();
    } else {
      this.enableScroll();
    }
  }

  handleClickLink() {
    this.toggleNav = false;
    this.enableScroll();
  }

  private disableScroll() {
    document.body.classList.add('no-scroll');
  }

  private enableScroll() {
    document.body.classList.remove('no-scroll');
  }
}
