import { Component, ViewChild } from '@angular/core';
import { testimonials } from '../../data/testimonials.data';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-testimonials',
  standalone: false,
  templateUrl: './testimonials.component.html',
  styleUrl: './testimonials.component.scss'
})
export class TestimonialsComponent {

  testimonials = testimonials;

  carouselConfig = {
    paused: false,
    unpauseOnArrow: false,
	  pauseOnIndicator: false,
    pauseOnHover: true,
    pauseOnFocus: true,
  }

	@ViewChild('carousel', { static: true }) carousel: NgbCarousel;

	togglePaused() {
		if (this.carouselConfig.paused) {
			this.carousel.cycle();
		} else {
			this.carousel.pause();
		}
		this.carouselConfig.paused = !this.carouselConfig.paused;
	}

	onSlide(slideEvent: NgbSlideEvent) {
		if (
			this.carouselConfig.unpauseOnArrow &&
			slideEvent.paused &&
			(slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)
		) {
			this.togglePaused();
		}
		if (this.carouselConfig.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
			this.togglePaused();
		}
	}
}
