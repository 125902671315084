import { Component, OnInit } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { Project, ProjectThumbnail } from './../model/model.vo';
import { ProjectService } from './../shared/service/project.service';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingIndicatorComponent } from '../shared/view/loading-indicator/loading-indicator.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, LoadingIndicatorComponent, TranslateModule]
})
export class WorkComponent implements OnInit {
  // Expose projects$ observable with thumbnails computed via forkJoin
  projects$ = this.projectService.projects$.pipe(
    filter(projects => projects.length > 0),
    map(projects => this.projectService.sortProjects(projects)),
    switchMap(sortedProjects =>
      sortedProjects.length
      ? forkJoin(
          sortedProjects.map((project, index) =>
            this.projectService.getWorkThumbnail(project).pipe(
              map(url => ({
                ...project,
                workItemThumbnailImageURL: url || this.getFallbackWorkItemThumbnailImage(project),
                canBeDisplayed: true,
                hovered: false
              }))
            )
          )
        )
      : of([])
    ),
    catchError(error => {
      console.error(error);
      return of([]);
    })
  );

  constructor(
    private router: Router,
    private projectService: ProjectService
  ) {}

  ngOnInit() {}

  showProjectDetails(project: Project): void {
    this.router.navigate(['/work', project.linkName]);
  }

  private getFallbackWorkItemThumbnailImage(project: Project): string {
    return project.imageURL ? project.imageURL : '../assets/images/project-404.svg';
  }
}
