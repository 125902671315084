import { environment } from './../environments/environment';

import { ContactComponent } from './contact/contact.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { HelloComponent } from './hello/hello.component';
import { NavComponent } from './nav/nav.component';
import { FooterComponent } from './footer/footer.component';
import { WorkComponent } from './work/work.component';
import { AuthComponent } from './auth/auth.component';
import { ImprintAndDataPrivacyComponent } from './imprint-and-data-privacy/imprint-and-data-privacy.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { ProjectService } from './shared/service/project.service';
import { FirebaseStorageService } from './shared/service/firebase-storage.service';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { LoadingIndicatorComponent } from './shared/view/loading-indicator/loading-indicator.component';
import { CookieHintComponent } from './cookie-hint/cookie-hint.component';
import { AboutComponent } from './about/about.component';
import { WhatDoNextComponent } from './shared/view/what-do-next/what-do-next.component';
import { AddOrEditProjectComponent } from './dashboard/projects/add-or-edit-project.component';
import { CustomProjectSiteComponent } from './dashboard/projects/custom-project-site/custom-project-site.component';
import { ReferralComponent } from './referral/referral.component';
import { ImgFallbackDirective } from './shared/directive/img-fallback.directive';
import { CvUploadComponent } from './dashboard/cv-upload/cv-upload.component';
import { Firestore, getFirestore } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { ClientLogosComponent } from './shared/view/client-logos/client-logos.component';
import { SomethingWentWrongComponent } from './shared/view/something-went-wrong/something-went-wrong.component';
import { AngularEnvironmentComponent } from './dashboard/angular-environment/angular-environment.component';
import { TestimonialsComponent } from './shared/view/testimonials/testimonials.component';
import { CvDownloadButtonComponent } from './shared/view/cv-download-button/cv-download-button.component';

// Initialize Firebase
const firebaseApp = initializeApp(environment.firebaseConfig);

@NgModule({
  declarations: [
    AppComponent,
    ContactComponent,
    HelloComponent,
    NavComponent,
    FooterComponent,
    WorkComponent,
    AuthComponent,
    ImprintAndDataPrivacyComponent,
    DashboardComponent,
    MaintenanceComponent,
    ProjectDetailsComponent,
    LoadingIndicatorComponent,
    CookieHintComponent,
    AboutComponent,
    WhatDoNextComponent,
    AddOrEditProjectComponent,
    CustomProjectSiteComponent,
    ReferralComponent,
    CvUploadComponent,
    ImgFallbackDirective,
    ClientLogosComponent,
    SomethingWentWrongComponent,
    AngularEnvironmentComponent,
    TestimonialsComponent,
    CvDownloadButtonComponent,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    HttpClientModule,
    NgbModule,
    ReactiveFormsModule
  ],
  providers: [
    ProjectService,
    { provide: Firestore, useValue: getFirestore(firebaseApp) },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
