import { Component, OnInit } from '@angular/core';
import { Client } from 'src/app/model/client.model';
import { clients } from '../../data/clients';
import { fadeInUpOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-client-logos',
  templateUrl: './client-logos.component.html',
  styleUrls: ['./client-logos.component.scss'],
  animations: [
    fadeInUpOnEnterAnimation({ anchor: 'enter'}),
  ]
})
export class ClientLogosComponent implements OnInit {
  clients: Client[]

  ngOnInit(): void {
    this.clients = clients

    // Sort clients alphabetically
    this.clients.sort((a, b) => (a.shortname < b.shortname ? -1 : 1))
  }
}
