import { FirebaseStorageService } from './../../shared/service/firebase-storage.service';
import { ProjectService } from '../../shared/service/project.service';
import { Project, KindOfProduct } from '../../model/model.vo';
import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { isDef } from 'src/app/core/common.utils';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-add-or-edit-project',
  templateUrl: './add-or-edit-project.component.html',
  styleUrls: ['./add-or-edit-project.component.scss']
})
export class AddOrEditProjectComponent implements OnInit {

  // MARK: Input

  @Input('projectToEdit') project: Project;
  @Input() modus: AddOrEditProjectModus;

  public projectFormGroup: FormGroup = this.formBuilder.group({
    shortName: new FormControl<string>(''),
    name: new FormControl<string>(''),
    linkName: new FormControl<string>(''),
    kind: new FormControl<KindOfProduct>(KindOfProduct.iOS),
    client: new FormControl<string>(''),
    start: new FormControl<string>(''),
    end: new FormControl<string>(''),
    description: new FormControl<string>(''),
    descriptionTechnical: new FormControl<string>(''),
    moreInformations: new FormGroup({
      title: new FormControl<string|null>(null),
      url: new FormControl<string|null>(null)
    }),
    customProjectSite: new FormControl<string|null>(null),
    role: new FormControl<string>(''),
    industry: new FormControl<string>(''),
    tags: new FormControl<string>('')
  });
  public KindOfProduct = KindOfProduct;

  public projectImagePreview: any;
  private projectImageSelected: object;

  public isNetworkRequest = false;
  public activeProjectDescriptionTab = 1;

  closeResult = '';

  constructor(
    private domSanitizer: DomSanitizer,
    private modalConfig: NgbModalConfig,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private projectService: ProjectService,
    public storageService: FirebaseStorageService
  ) {
    this.modalConfig.backdrop = 'static';
  }

  ngOnInit() {
    this.setProjectFormGroupValues();
  }

  open(content) {
    if (!isDef(this.modus)) {
      console.error('The modal have a unknown `modus` as parameter');
      return;
    }

    const modalOptions = {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl'
    };

    const modalRef = this.modalService.open(content, modalOptions);

    modalRef.result.then( result => {
      this.closeResult = `Closed with: ${result}`;
    }, reason => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  get modalTitle() {
    switch (this.modus) {
      case 'add':
        return 'Add new project';
      case 'edit':
        return isDef(this.project)
          ? `Edit project: ${this.project.shortName}`
          : 'Edit unkown project';
    }
  }

  get linkName() {
    return this.projectFormGroup.value.name?.makeLowerCaseDashed() || '';
  }

  public previewProjectImage(event: any) {
    // Image to Model
    this.projectImageSelected = event.target.files[0];

    // Image Preview for View
    if (event.target.files && event.target.files[0]) {

      const fileReader = new FileReader();
      fileReader.onload = _ => {
        this.projectImagePreview = this.domSanitizer.bypassSecurityTrustUrl(fileReader.result as string);
      };
      fileReader.readAsDataURL(event.target.files[0]);
    }
  }

  public onUpdateProject() {
    const updateProject = this.prepareProjectForFirestore({ ...this.project });

    // Project Data
    this.updateProjectInFirestore(updateProject).then( _ => {
      this.isNetworkRequest = false;
      this.projectFormGroup.enable();

      // Project Image
      if (isDef(this.projectImageSelected)) {
        this.saveProjectImageInStorage(this.projectImageSelected, updateProject.linkName).then( _ => {
        }, reason => {
          console.error('Store project image rejected: ', reason);
        });
      }

    }, reason => {
      this.isNetworkRequest = false;
      this.projectFormGroup.enable();
      console.error('Update project rejected, reason:', reason);
    });
  }

  public onAddProject() {
    const newProject = this.prepareProjectForFirestore(new Project());

    // Project Data
    this.createProjectInFirestore(newProject).then( _ => {
      this.isNetworkRequest = false;
      this.projectFormGroup.enable();

      // Project Image
      if (isDef(this.projectImageSelected)) {
        this.saveProjectImageInStorage(this.projectImageSelected, newProject.linkName).then( _ => {
        }, reason => {
          console.error('Store project image rejected: ', reason);
        });
      }

    }, reason => {
      this.isNetworkRequest = false;
      this.projectFormGroup.enable();
      console.error('Create project rejected, reason: ', reason);
    });
  }

  // MARK: - Private

  private setProjectFormGroupValues() {
    if (isDef(this.project)) {
      this.projectFormGroup.patchValue({
        shortName: this.project.shortName,
        name: this.project.name,
        linkName: this.project.linkName,
        kind: this.project.kind,
        client: this.project.client,
        start: this.project.start,
        end: this.project.end,
        description: this.project.description,
        descriptionTechnical: this.project.descriptionTechnical,
        customProjectSite: this.project.customProjectSite,
        role: this.project.role,
        industry: this.project.industry,
        tags: this.project.tags.join(', ')
      });

      if (isDef(this.project.moreInformations)) {
        this.projectFormGroup.get('moreInformations').setValue({
          title: this.project.moreInformations.title,
          url: this.project.moreInformations.url
        });
      }
    }
  }

  private prepareProjectForFirestore(project: Project): Project {
    // Fill from formular data
    project.name = this.projectFormGroup.value.name;
    project.shortName = this.projectFormGroup.value.shortName;
    project.linkName = this.linkName;
    project.kind = KindOfProduct[this.projectFormGroup.value.kind as string];
    project.client = this.projectFormGroup.value.client;
    project.industry = this.projectFormGroup.value.industry;
    project.role = this.projectFormGroup.value.role;
    project.start = this.projectFormGroup.value.start;
    project.end = this.projectFormGroup.value.end;
    project.description = this.projectFormGroup.value.description;
    project.descriptionTechnical = this.projectFormGroup.value.descriptionTechnical;
    project.moreInformations = {
      title: this.projectFormGroup.get('moreInformations').value.title,
      url: this.projectFormGroup.get('moreInformations').value.url
    };
    project.customProjectSite = this.projectFormGroup.value.customProjectSite;
    if (isDef(this.projectFormGroup.value.tags)) {
      project.tags = this.projectFormGroup.value.tags.replace(/\s/g, '').split(',');
    }

    // Parse to pure JS object (firebase requirement)
    return project;
  }

  private loadProjectImage() {
    this.storageService.fetchProjectImageURL(this.project.linkName)
      .subscribe({
        next: (imageURL: string) => this.project.imageURL = imageURL,
        error: (error: Error) => console.error(error)
      });
  }

  private createProjectInFirestore(project: Project): Promise<void> {
    this.projectFormGroup.disable();
    return this.projectService.createProject(project);
  }

  private updateProjectInFirestore(project: Project): Promise<void> {
    this.projectFormGroup.disable();
    return this.projectService.updateProject(project);
  }

  private saveProjectImageInStorage(image: any, imageName: string) {
    return this.storageService.storeProjectImage(imageName, image);
  }
}

export enum AddOrEditProjectModus {
  Add = 'add', Edit = 'edit'
}
