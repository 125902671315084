<footer class="py-5">
  <div class="container">

    <!-- <div class="d-flex flex-row justify-content-between align-items-center">
      <a
      href="https://app-entwickler-verzeichnis.de"
      title="iOS, Web Entwicklung Felix Schmidt bei Görlitz (iPhone, iPad)"
    >
      <img
        src="https://app-entwickler-verzeichnis.de/images/corporate/app-entwickler-stempel.png"
        alt="Icon Mitglied App-Entwickler-Verzeichnis"
        width="80"
        height="80"
      />
    </a>
    </div> -->

    <hr>

    <div class="d-flex flex-row justify-content-between align-items-start">

      <div class="d-flex flex-column flex-lg-row justify-content-space align-items-start align-items-lg-center gap-2 gap-lg-6">
        <aside class="text-information"
           [innerHTML]="texts().copyright">
        </aside>

        <a routerLink="/imprint-and-data-privacy"
           routerLinkActive="active"
           class="text-decoration-none"
           [innerHTML]="( 'components.footer.IMPRINT_DATA_PRIVACY' | translate)">
        </a>
      </div>

      <label for="language-select" class="sr-only">{{ 'components.footer.a11y.LABEL_SELECT_LANG' | translate }}</label>
      <select id="language-select" class="custom-select mr-sm-2" (change)="onChangeLanguage($event.target.value)">
        @for(lang of languages; track lang) {
          <option [value]="lang"
                  [selected]="languageService.currentLanguage() === lang">
            {{ 'components.footer.LANG_' + lang.toUpperCase() | translate }}
          </option>
        }
      </select>

    </div>
  </div>
</footer>
