import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpClient: HttpClient) { }

  /**
   * Sends the data of the contact form to the backend to receive by email.
   * @param contactFormSendable Contact form data.
   * @returns An observable with the response from backend.
   */
  public sendContactFormViaEmail(contactFormSendable: ContactFormSendable): Observable<ContactFormSendResponse> {
    return this.httpClient.post<any>('api/contact.php', this.mapToFormDataFrom(contactFormSendable));
  }

  // MARK: Private

  /**
   * Maps a `ContactFormSendable` object to a `FormData` object.
   * @param contactFormSendable
   * @returns A `FormData`object.
   */
  private mapToFormDataFrom(contactFormSendable: ContactFormSendable): FormData {
    const formData = new FormData();
    formData.append('name', contactFormSendable.name);
    formData.append('email', contactFormSendable.email);
    formData.append('phone', contactFormSendable.phone);
    formData.append('purpose', contactFormSendable.purpose);
    formData.append('message', contactFormSendable.message);
    return formData
  }
}

/**
 * POST-Request payload model to send contact form data to the API.
 * /contact.php
 */
export interface ContactFormSendable {
  name: string;
  email: string;
  phone: string;
  purpose: string;
  message: string;
}

/**
 * POST-Response model when contact form data was sent to the API.
 * / contact.php
 * */
export interface ContactFormSendResponse {
  success: boolean;
  message: string;
}
