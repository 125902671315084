import { Project } from './../model/model.vo';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectService } from '../shared/service/project.service';
import { appEnvironmentAsText } from '../core/common.utils';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  projects: Project[] = null;

  isLoading = true;
  appEnvironmentText = appEnvironmentAsText();

  constructor(
    public projectService: ProjectService,
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.projectService.projects$.subscribe({
        next: projects => { this.projects = projects; this.isLoading = false },
        error: error => { console.error(error); this.isLoading = false },
      })
    );
  }

  ngOnDestroy(): void {
      this.subscriptions.unsubscribe()
  }

  private subscriptions = new Subscription();
}
