import { Component, computed, inject } from '@angular/core';
import { CvService } from '../shared/cv.service';
import { CVInformation } from '../model/FbDatabase/cv-information.model';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingIndicatorComponent } from '../shared/view/loading-indicator/loading-indicator.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-cv-locales',
  templateUrl: './cv-locales.component.html',
  styleUrl: './cv-locales.component.scss',
  standalone: true,
  imports: [TranslateModule, LoadingIndicatorComponent, CommonModule]
})
export class CvLocalesComponent {

  chVersions = computed(() => {
    return this.cvService.cvVersionsAvailable().filter(cvInformation => cvInformation.locale === 'CH')
  })

  deVersions = computed(() => {
    return this.cvService.cvVersionsAvailable().filter(cvInformation => cvInformation.locale === 'DE')
  })

  loading = computed(() => this.chVersions().length === 0 && this.deVersions().length === 0)

  openCV(cvInformation: CVInformation): void {
    this.cvService.openCV(cvInformation)
  }

  private cvService = inject(CvService)
}
