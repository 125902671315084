// 1:1 animate.css class names!
export enum AnimationStyle {
  FadeIn = 'fadeIn',
  FadeOut = 'fadeOut',
  Bounce = 'bounce',
  Flash = 'flash',
  Pulse = 'pulse',
  RubberBand = 'rubberBand',
  Shake = 'shake',
  HeadShake = 'headShake',
  Swing = 'swing',
  Tada = 'tada',
  Wobble = 'wobble',
  Jello = 'jello',
  HeartBeat = 'heartBeat',
  BounceIn = 'bounceIn',
  BounceInDown = 'bounceInDown',
  BounceInLeft = 'bounceInLeft',
  BounceInRight = 'bounceInRight',
  BounceInUp = 'bounceInUp',
  BounceOut = 'bounceOut',
  BounceOutDown = 'bounceOutDown',
  BounceOutLeft = 'bounceOutLeft',
  BounceOutRight = 'bounceOutRight',
  BounceOutUp = 'bounceOutUp',
  FadeInDown = 'fadeInDown',
  FadeInDownBig = 'fadeInDownBig',
  FadeInLeft = 'fadeInLeft',
  FadeInLeftBig = 'fadeInLeftBig',
  FadeInRight = 'fadeInRight',
  FadeInRightBig = 'fadeInRightBig',
  FadeInUp = 'fadeInUp',
  FadeInUpBig = 'fadeInUpBig',
  FadeOutDown = 'fadeOutDown',
  FadeOutDownBig = 'fadeOutDownBig',
  FadeOutLeft = 'fadeOutLeft',
  FadeOutLeftBig = 'fadeOutLeftBig',
  FadeOutRight = 'fadeOutRight',
  FadeOutRightBig = 'fadeOutRightBig',
  FadeOutUp = 'fadeOutUp',
  FadeOutUpBig = 'fadeOutUpBig',
  Flip = 'flip',
  FlipInX = 'flipInX',
  FlipInY = 'flipInY',
  FlipOutX = 'flipOutX',
  FlipOutY = 'flipOutY',
  LightSpeedIn = 'lightSpeedIn',
  LightSpeedOut = 'lightSpeedOut',
  RotateIn = 'rotateIn',
  RotateInDownLeft = 'rotateInDownLeft',
  RotateInDownRight = 'rotateInDownRight',
  RotateInUpLeft = 'rotateInUpLeft',
  RotateInUpRight = 'rotateInUpRight',
  RotateOut = 'rotateOut',
  RotateOutDownLeft = 'rotateOutDownLeft',
  RotateOutDownRight = 'rotateOutDownRight',
  RotateOutUpLeft = 'rotateOutUpLeft',
  RotateOutUpRight = 'rotateOutUpRight',
  Hinge = 'hinge',
  JackInTheBox = 'jackInTheBox',
  RollIn = 'rollIn',
  RollOut = 'rollOut',
  ZoomIn = 'zoomIn',
  ZoomInDown = 'zoomInDown',
  ZoomInLeft = 'zoomInLeft',
  ZoomInRight = 'zoomInRight',
  ZoomInUp = 'zoomInUp',
  ZoomOut = 'zoomOut',
  ZoomOutDown = 'zoomOutDown',
  ZoomOutLeft = 'zoomOutLeft',
  ZoomOutRight = 'zoomOutRight',
  ZoomOutUp = 'zoomOutUp',
  SlideInDown = 'slideInDown',
  SlideInLeft = 'slideInLeft',
  SlideInRight = 'slideInRight',
  SlideInUp = 'slideInUp',
  SlideOutDown = 'slideOutDown',
  SlideOutLeft = 'slideOutLeft',
  SlideOutRight = 'slideOutRight',
  SlideOutUp = 'slideOutUp',
}
