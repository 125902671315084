import { ApplicationConfig, enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { Firestore, getFirestore } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { AngularFireModule } from '@angular/fire/compat';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Initialize Firebase
const firebaseApp = initializeApp(environment.firebaseConfig);

// Factory function for TranslateHttpLoader
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + new Date().getTime());
}

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: Firestore, useValue: getFirestore(firebaseApp) },
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(
      AngularFireModule.initializeApp(
        environment.firebaseConfig
      ),
      AppRoutingModule,
      BrowserAnimationsModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        },
        defaultLanguage: 'de'
      }),
    ),
  ],
};

if (environment.production) {
  enableProdMode();
}

// Note: AppModule is no longer used.
bootstrapApplication(AppComponent, appConfig)
  .catch(err => console.error(err));
