import { AfterViewInit, Component, OnInit } from '@angular/core';
import { aboutMeFunFacts } from '../shared/data/about-me-fun-facts';
import { multipleRandomElements } from '../shared/helpers/helpers';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-hello',
  templateUrl: './hello.component.html',
  styleUrls: ['./hello.component.scss']
})
export class HelloComponent implements OnInit, AfterViewInit {

  aboutMeFunFacts: string[];

  constructor(
    private route: ActivatedRoute,
    private viewPortScroller: ViewportScroller
  ) { }

  // MARK: - Life Cycle

  ngOnInit() {
    this.randomizeThreeFunFactsAboutMe();
  }

  ngAfterViewInit(): void {
    this.initViewScrollByAnchorParameterInURL();
  }

  // MARK: - Public

  shakeFunFacts() {
    this.randomizeThreeFunFactsAboutMe();
  }

  // MARK: - Private

  private randomizeThreeFunFactsAboutMe() {
    const funFacts = Object.keys(aboutMeFunFacts).map(key => aboutMeFunFacts[key]);

    this.aboutMeFunFacts = multipleRandomElements(funFacts, 3);
  }

  /**
   * When the URL / Route was called by a achor (first route parameter, example hello/some-anchor)
   */
  private initViewScrollByAnchorParameterInURL() {
    // Workaround
    // Subscribe after a minor delay, because the Angular life cycle hooks are not enough,
    // to load all the view content, that the scroll position is exactly on anchor position
    setTimeout(() => {
      this.route.params.subscribe( params => {
        const anchor = params['anchor'] || '';

        switch (anchor.toLocaleLowerCase()) {
          case 'testimonials':
          case 'honest-voices':
          case 'honestvoices':
            this.viewPortScroller.scrollToAnchor('testimonials');
          default:
            break;
        }
      })
    }, 100)

  }
}
