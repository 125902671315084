<!-- TODO: implement -->
<!-- <a class="skip-to-main-content-link" href="#main-content">Skip to main content</a> -->

<nav
  id="navigation"
  role="navigation"
  [attr.aria-label]="'components.nav.a11y.LABEL_NAVIGATION' | translate"
  [class]="toggleNav ? 'show' : ''">

  <div class="nav-content">
    <a routerLink="/hello"
       class="logo d-flex justify-content-between align-items-center gap-2"
       [attr.aria-label]="'components.nav.a11y.LABEL_HOME' | translate">
      <img src="../../assets/images/felix-schmidt-software-logo.svg"
           [alt]="'components.nav.LOGO_ALT' | translate"
           height="40px"
           width="auto">
    </a>
    <div class="nav-icon"
         (click)="toggleNavigation()"
         aria-controls="nav-links"
         [attr.aria-expanded]="toggleNav"
         [attr.aria-label]="'components.nav.a11y.LABEL_TOGGLE_NAV' | translate">
      <div class="bar one" aria-hidden="true"></div>
      <div class="bar two" aria-hidden="true"></div>
      <div class="bar three" aria-hidden="true"></div>
    </div>
    <div id="nav-links" class="nav-links-container">
      <a routerLink="/hello"
         routerLinkActive="active"
         (click)="handleClickLink()"
         aria-current="page"
         [attr.aria-label]="'components.nav.a11y.LABEL_LINK_HELLO' | translate">{{ 'components.nav.HELLO' | translate }}</a>
      <a routerLink="/about"
         routerLinkActive="active"
         (click)="handleClickLink()"
         aria-current="page"
         [attr.aria-label]="'components.nav.a11y.LABEL_LINK_ABOUT' | translate">{{ 'components.nav.ABOUT' | translate }}</a>
      <a routerLink="/work"
         routerLinkActive="active"
         (click)="handleClickLink()"
         aria-current="page"
         [attr.aria-label]="'components.nav.a11y.LABEL_LINK_WORK' | translate">{{ 'components.nav.WORK' | translate }}</a>
      <a routerLink="/contact"
         routerLinkActive="active"
         (click)="handleClickLink()"
         aria-current="page"
         [attr.aria-label]="'components.nav.a11y.LABEL_LINK_CONTACT' | translate">{{ 'components.nav.CONTACT' | translate }}</a>
      <a routerLink="/dashboard"
         routerLinkActive="active"
         *ngIf="authService.isAuthenticated"
         (click)="handleClickLink()"
         aria-current="page"
         [attr.aria-label]="'components.nav.a11y.LABEL_LINK_DASHBOARD' | translate">{{ 'components.nav.DASHBOARD' | translate }}</a>
      <a routerLink="/auth"
         routerLinkActive="active"
         *ngIf="authService.isAuthenticated"
         (click)="authService.logout()"
         [attr.aria-label]="'components.nav.a11y.LABEL_LOGOUT' | translate">{{ 'components.nav.LOGOUT' | translate }}</a>
      <div class="social-media-icons">
        <p *ngIf="toggleNav" class="d-lg-none mt-2 mb-0 text-stone-500">
          <small>{{ 'components.nav.VISIT_SOCIAL' | translate }} <fa-icon [icon]="socialMediaIcons.externalLink" class="d-inline-block mx-1" aria-hidden="true"></fa-icon></small>
        </p>
        <div class="d-flex flex-wrap">
          <a class="social-media-icon"
             routerLink="/ref/{{ referrals.linkedin.name }}"
             [title]="'components.nav.LINKEDIN_TITLE' | translate"
             [attr.aria-label]="'components.nav.a11y.LABEL_SOCIAL_LINKEDIN' | translate"><fa-icon [icon]="socialMediaIcons.linkedIn" aria-hidden="true"></fa-icon></a>
          <a class="social-media-icon"
             routerLink="/ref/{{ referrals.xing.name }}"
             [title]="'components.nav.XING_TITLE' | translate"
             [attr.aria-label]="'components.nav.a11y.LABEL_SOCIAL_XING' | translate"><fa-icon [icon]="socialMediaIcons.xing" aria-hidden="true"></fa-icon></a>
          <a class="social-media-icon"
             routerLink="/ref/{{ referrals.github.name }}"
             [title]="'components.nav.GITHUB_TITLE' | translate"
             [attr.aria-label]="'components.nav.a11y.LABEL_SOCIAL_GITHUB' | translate"><fa-icon [icon]="socialMediaIcons.github" aria-hidden="true"></fa-icon></a>
        </div>
      </div>
    </div>
  </div>
</nav>
