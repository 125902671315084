// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyCbIAU6XLKbcKxL0kZ5sypyKBrP94EjlGQ',
    authDomain: 'felix-schmidt-software.firebaseapp.com',
    databaseURL: 'https://felix-schmidt-software.firebaseio.com',
    projectId: 'felix-schmidt-software',
    storageBucket: 'felix-schmidt-software.appspot.com',
    messagingSenderId: '206031768549',
    appId: '1:206031768549:web:4361eb5908cbf4ff9abd0c'
  },
  angularVersion: '17',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
