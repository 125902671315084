import { referrals, ReferralsDataSource } from './../shared/data/referrals.data';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { faLinkedinIn, faXing, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  public socialMediaIcons = {
    github: faGithub,
    linkedIn: faLinkedinIn,
    xing: faXing,
    externalLink: faExternalLinkAlt
  };

  public toggleNav = false;

  public referrals: ReferralsDataSource

  constructor(public authService: AuthService) { }

  ngOnInit() {
    this.referrals = referrals;
  }
}
