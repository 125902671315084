<div class="d-flex flex-column align-items-center gap-3 py-5">
  <div class="d-flex justify-content-center align-items-center gap-2">
    <img
      src="../../../../assets/icons/something-went-wrong-emoji.svg"
      alt="Something went wrong emoji icon"
      class="icon"
    >
    <h3 class="headline">{{ headline }}</h3>
  </div>
  <p class="body">{{ body }}</p>
  <p class="body">Mmmh, maybe your internet lacks. <br> Please check it and try again.</p>

  <button
    class="btn btn-primary btn-dark btn-fs-medium"
    (click)="handleButtonClick()"
  >
    Try again
  </button>
</div>
