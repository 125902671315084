<div class="container">

  <header class="text-center my-5">
    <h1 class="display-5 text-dark" [innerHTML]="'cv-locales.title' | translate"></h1>
    <p class="lead" [innerHTML]="'cv-locales.lead' | translate"></p>
  </header>

  @if (loading()) {
    <app-loading-indicator></app-loading-indicator>
  }
  @else {
    <ng-template #cvList let-cvInformations let-title="title" let-isCh="isCh">
      <h2 [innerHTML]="title | translate"></h2>
      @if (isCh) {
        <p [innerHTML]="'cv-locales.ch_note' | translate"></p>
      }
      <ul class="py-3">
        @for (cvInformation of cvInformations; track cvInformation) {
          <li class="d-flex justify-content-start align-items-start gap-5">
            <img src="../../assets/icons/cv-file-icon.svg" alt="" class="cv-icon">
            <div class="d-flex flex-column justify-content-center align-items-start gap-1">
              <h3 class="h5" [innerHTML]="'cv-locales.cv_language.' + ((isCh && cvInformation.language === 'de') ? 'de_ch' : cvInformation.language) | translate"></h3>
              <span class="text-gray-500" [innerHTML]="cvInformation.version"></span>
              <button class="btn btn-link p-0" (click)="openCV(cvInformation)">Download</button>
            </div>
          </li>
        }
      </ul>
    </ng-template>

    <ng-container
      [ngTemplateOutlet]="cvList"
      [ngTemplateOutletContext]="{
        $implicit: chVersions(),
        title: 'cv-locales.switzerland',
        isCh: true
      }"
    >
    </ng-container>

    <ng-container
      [ngTemplateOutlet]="cvList"
      [ngTemplateOutletContext]="{
        $implicit: deVersions(),
        title: 'cv-locales.eu_international',
        isCh: false
      }"
    >
    </ng-container>
  }

</div>
