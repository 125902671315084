import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { FSLanguage, LanguageService } from '../shared/service/language.service';
import { LangChangeEvent, TranslateService, TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [TranslateModule]
})
export class FooterComponent implements OnInit, OnDestroy {

  languages: FSLanguage[] = [FSLanguage.German, FSLanguage.English]
  texts = signal({copyright: ''})

  languageService = inject(LanguageService)
  translationService = inject(TranslateService)

  constructor() { }

  ngOnInit() {
    this.updateTexts()

    // Observe language change
    this.subscriptions.add(
      this.translationService.onLangChange
        .subscribe( _ => this.updateTexts())
    )
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onChangeLanguage(lang: FSLanguage): void {
    console.log()
    this.languageService.changeLanguage(lang)
  }

  private updateTexts(): void {
    this.updateCopyright()
  }

  private updateCopyright(): void {
    const currentYear = this.currentYear()
    this.subscriptions.add(
      this.translationService.get('components.footer.COPYRIGHT', { currentYear })
      .subscribe(copyright => {
        this.texts.update(current => ({ ...current, copyright}))
      })
    )
  }

  private currentYear(): number {
    return new Date().getFullYear();
  }

  private subscriptions = new Subscription()
}
