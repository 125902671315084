import { inject, Injectable, OnDestroy, signal } from '@angular/core';
import { FirebaseFirestoreDatabaseService } from './service/firebase-firestore-database.service';
import { Subject, Subscription } from 'rxjs';
import { CVInformation } from '../model/FbDatabase/cv-information.model';

@Injectable({
  providedIn: 'root'
})
export class CvService {

  cvVersionsAvailable = signal<CVInformation[]>([])

  constructor() {
    this.refreshCvVersionsAvailable()
  }

  openCV(cvInformation: CVInformation): void {
    if (cvInformation?.downloadURL) {
      window.open(cvInformation.downloadURL, '_blank')
    }
  }

  refreshCvVersionsAvailable(): void {
    this.fbDatabaseService.getAllCVInformations().then(
      cvInformations => this.cvVersionsAvailable.set(cvInformations)
    )
  }

  private fbDatabaseService = inject(FirebaseFirestoreDatabaseService)
}
