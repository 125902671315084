import { AfterViewInit, Component, OnInit } from '@angular/core';
import { multipleRandomElements } from '../shared/helpers/helpers';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { servicePromoItemModels } from '../shared/data/services-promo-item-models.data';
import { AnimationStyle } from '../shared/constants/animation-style.enum';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ClientLogosComponent } from '../shared/view/client-logos/client-logos.component';
import { PromoItemComponent } from '../shared/components/promo-item/promo-item.component';
import { PromoItemsComponent } from '../shared/components/promo-items/promo-items.component';
import { TestimonialsComponent } from '../shared/view/testimonials/testimonials.component';
import { CvDownloadButtonComponent } from '../shared/view/cv-download-button/cv-download-button.component';
import { WhatDoNextComponent } from '../shared/view/what-do-next/what-do-next.component';
import { AnimateIfInViewportDirective } from '../shared/directive/in-viewport.directive';

@Component({
  selector: 'app-hello',
  templateUrl: './hello.component.html',
  styleUrls: ['./hello.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    RouterModule,
    ClientLogosComponent,
    PromoItemComponent,
    PromoItemsComponent,
    TestimonialsComponent,
    CvDownloadButtonComponent,
    WhatDoNextComponent,
    AnimateIfInViewportDirective
  ],
})
export class HelloComponent implements OnInit, AfterViewInit {

  animationStyle = AnimationStyle

  servicePromoItemModels = servicePromoItemModels
  aboutMeFunFacts: string[] = [];
  private allFunFacts: string[];

  constructor(
    private route: ActivatedRoute,
    private viewPortScroller: ViewportScroller,
    private translate: TranslateService
  ) {}

  // MARK: - Life Cycle

  ngOnInit() {
    this.subscriptions.add(
      this.translate.get('start.aboutMeIn60Seconds').subscribe(translations => {
      this.allFunFacts = Object.keys(translations)
        .filter(key => key.startsWith('FUN_'))
        .map(key => translations[key]);
      this.shakeFunFacts();
      })
    )
  }

  ngAfterViewInit(): void {
    this.initViewScrollByAnchorParameterInURL();
  }

  // MARK: - Public

  shakeFunFacts() {
    this.aboutMeFunFacts = multipleRandomElements([...this.allFunFacts], 3);
  }

  // MARK: - Private

  /**
   * When the URL / Route was called by a achor (first route parameter, example hello/some-anchor)
   */
  private initViewScrollByAnchorParameterInURL() {
    // Workaround
    // Subscribe after a minor delay, because the Angular life cycle hooks are not enough,
    // to load all the view content, that the scroll position is exactly on anchor position
    setTimeout(() => {
      this.route.params.subscribe( params => {
        const anchor = params['anchor'] || '';

        switch (anchor.toLocaleLowerCase()) {
          case 'testimonials':
          case 'honest-voices':
          case 'honestvoices':
            this.viewPortScroller.scrollToAnchor('testimonials');
          default:
            break;
        }
      })
    }, 100)
  }

  private subscriptions = new Subscription()
}
