import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-something-went-wrong',
  templateUrl: './something-went-wrong.component.html',
  styleUrls: ['./something-went-wrong.component.scss']
})
export class SomethingWentWrongComponent {
  @Input() headline: string = 'Oops!';
  @Input() body: string = '';

  @Output() onButtonClick: EventEmitter<void> = new EventEmitter();

  handleButtonClick(): void {
    this.onButtonClick.emit();
  }
}
