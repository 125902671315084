import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FirebaseFirestoreDatabaseService } from '../../service/firebase-firestore-database.service';
import { Observable, combineLatest, filter, from, map, switchMap, tap } from 'rxjs';
import { CVInformation } from 'src/app/model/FbDatabase/cv-information.model';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { CountryCode, LocaleService } from '../../service/locale.service';
import { LanguageService } from '../../service/language.service';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-cv-download-button',
  templateUrl: './cv-download-button.component.html',
  styleUrls: ['./cv-download-button.component.scss'],
  imports: [CommonModule, FontAwesomeModule, TranslateModule, RouterModule]
})
export class CvDownloadButtonComponent implements OnInit {

  @Input() title: string = 'CV - Felix Schmidt'
  @Input() btnClass: string = 'btn btn-light btn-fs-large fade-in'
  @Input() showIcon = true

  @Output() cvInformationAvailable = new EventEmitter<CVInformation>()

  cvInformation$: Observable<CVInformation | undefined>

  icons = {
    fileAlt: faFileAlt
  }

  ngOnInit(): void {
    this.cvInformation$ = this.localeService.countryCode$.pipe(
      map( value => {
        console.log('CV fallback to version for german market.')
        return value === null ? CountryCode.Germany : value }),
      switchMap( countryCode => {
        return from(this.fbDatabaseService.getCVInformation({
          language: this.languageService.currentLanguage(),
          locale: countryCode
        }))
      }),
      tap(cvInformation => {
        this.cvInformationAvailable.emit(cvInformation)
      })
    )
  }

  openCV(cvInformation: CVInformation) {
    if (cvInformation?.downloadURL) window.open(cvInformation.downloadURL, '_blank')
  }

  private fbDatabaseService = inject(FirebaseFirestoreDatabaseService)
  private languageService = inject(LanguageService)
  private localeService = inject(LocaleService)
}
