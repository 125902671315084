import { LocalStorageService } from './../shared/service/local-storage.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookie-hint',
  templateUrl: './cookie-hint.component.html',
  styleUrls: ['./cookie-hint.component.scss']
})
export class CookieHintComponent implements OnInit {

  constructor(private localStorageService: LocalStorageService) { }

  ngOnInit() {
  }

  acceptCookies() {
    this.localStorageService.setItem('cookiesAccepted', 'true');
  }

}
