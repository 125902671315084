import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { referrals } from './../shared/data/referrals.data';

@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class ReferralComponent {

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe( paramMap => {
      const refItem = paramMap.get('referringItem').toLowerCase();

      if (referrals.hasOwnProperty(refItem)) {
        window.location.href = referrals[refItem].url;
      } else {
        this.router.navigate(['']);
      }
    });
  }
}
