<div class="container">
  <app-loading-indicator *ngIf="shouldShowLoadingSpinner"></app-loading-indicator>
</div>

<div *ngIf="project"
     [ngClass]="shouldShowLoadingSpinner ? 'd-none' : 'fade-in duration-2'">
  <section class="container">
    <div class="row">
      <article class="col-12 col-md-6
                      d-flex flex-column
                      justify-content-center align-items-stretch
                      order-2 order-md-1
                      pb-5"
               [ngClass]="shouldShowLoadingSpinner ? '' : 'slide-in-from-left'">

        <div class="d-flex
                    justify-content-between align-items-center
                    gap-3">
          <h1 class="product-name">{{project.name}}</h1>

          <div class="project-kind
                      d-flex
                      justify-content-center align-items-center
                      bg-dark">{{project.kind}}</div>
        </div>
        <hr>

        <div class="product-client-duration
                    d-flex
                    justify-content-between align-items-start
                    mb-3">
          <div>{{project.client}}</div>

          <div class="w-40 text-right">{{ project.end ? '' : 'since ' }}{{ project.start | date:'MM/yyyy' }}{{ project.end ? (' - ' + (project.end | date:'MM/yyyy')) : '' }}</div>
        </div>

        <div class="d-flex
                    justify-content-start align-items-start
                    gap-2
                    mb-2">

          <div style="width: 100px">Role </div><strong>{{project.role}}</strong>
        </div>

        <div class="d-flex
                    justify-content-start align-items-start
                    gap-2
                    mb-8">

          <div style="width: 100px">Industry </div><strong>{{project.industry}}</strong>
        </div>
      </article>

      <!-- Project Image -->
      <article id="product-image-wrapper"
               class="col-12 col-md-6
                      order-1 order-md-2
                      d-flex
                      justify-content-start align-items-center"
               [ngClass]="shouldShowLoadingSpinner ? '' : 'slide-in-from-right'">
        <img class="rounded"
             [src]="project.imageURL === '' ? '../assets/images/loading-spinner.gif' : project.imageURL" async
             (load)="imageWasLoaded()" />
      </article>
    </div>
  </section>

  <section id="product-details"
           class="inner-shadow bg-elevated-1-light
           py-7"
           [ngClass]="shouldShowLoadingSpinner ? '' : 'slide-in-from-bottom'">

    <!-- MARK: Custom Project Desc -->
    <article *ngIf="project.customProjectSite" [innerHTML]="project.customProjectSite"></article>


    <!-- MARK: No Custom Project Desc -->
    <article class="container"
         *ngIf="!project.customProjectSite">
      <div *ngIf="project.description"
               class="row justify-content-center">
        <div class="col-12 col-lg-9
                    d-flex flex-column
                    justify-content-between align-items-stretch
                    gap-5
                    pe-0 pe-lg-7">

          <div>
            <h2>About the product</h2>
            <p [innerHTML]="project.description"></p>
          </div>

          <div *ngIf="project.moreInformations">
            <h3 class="h4">More informations</h3>
            <p>You can find more informations about the project here (external):
              <a [href]="project.moreInformations.url"
                 [title]="project.moreInformations.title"
                 target="blank">
                 {{project.moreInformations.title}}
              </a>
            </p>
          </div>

          <div *ngIf="project.descriptionTechnical">
            <h2>Technical description</h2>
            <p [innerHTML]="project.descriptionTechnical"></p>
          </div>

        </div>

        <aside class="col-12 col-lg-3
                      pt-5 pt-lg-0">
          <h2>Highlights</h2>
          <p class="lead">Technical / Methodical</p>
          <div class="d-flex flex-wrap justify-content-start align-items-start gap-3 mt-5">
            @for(tag of project.tags; track tag) {
              <div class="product-tag bg-secondary">{{ tag }}</div>
            }
          </div>
        </aside>
      </div>
    </article>
  </section>

  <app-what-do-next [options]="{work: true, contact: true, socialConnect: true}"></app-what-do-next>

  <div class="inner-shadow fscolor-bg-elevated-2-light pt-32 pb-32">
    <div class="container">
      <div class="d-flex
                  justify-content-start align-items-start
                  gap-1">
        <p>*</p>
        <p><small>Software project where I'd participated with other developers as a full time permanent employee (external). The principals were exclusively clients of my former employer or the employer itselfs. It is hereby expressly pointed out, that I had no direct business relationship with the respective clients.</small></p>
      </div>
    </div>
  </div>

  <a title="{{nextProjectLinkTitle(project)}}"
     class="project-navigation
            next
            d-flex
            justify-content-center align-items-center"
     (click)="showNextProject(project)"
     *ngIf="nextProject(project)">
    <fa-icon [icon]="icons.arrowLeft"></fa-icon>
  </a>

  <a title="{{previousProjectLinkTitle(project)}}"
     class="project-navigation
            previous
            d-flex
            justify-content-center align-items-center"
     (click)="showPreviousProject(project)"
     *ngIf="previousProject(project)">
    <fa-icon [icon]="icons.arrowRight"></fa-icon>
  </a>
</div>
