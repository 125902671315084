import { isDef } from 'src/app/core/common.utils';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { ProjectService } from './../shared/service/project.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Project } from '../model/model.vo';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit {

  public project: Project = null;
  public shouldShowLoadingSpinner = true;
  public icons = {
    arrowLeft: faArrowLeft,
    arrowRight: faArrowRight
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public projectService: ProjectService
  ) {}

  ngOnInit() {

    this.route.paramMap.subscribe( paramMap => {
      const linkName = paramMap.get('projectLinkName');

      this.project = this.projectService.project(linkName);

      // When project could not provided by project service, maybe the user
      // has given url param manually. Try to find project in firestore
      if (!isDef(this.project)) {
        this.projectService.fetchProject(linkName).then(project => {
          this.project = project;
        }, error => {
          console.error('No valid project could be fetched.', error);
          // TODO: handle error with custom error page ...
        });
      }
    });
  }

  public nextProjectLinkTitle(currentProject: Project): string {
    const nextProject = this.nextProject(currentProject);

    if (nextProject) {
      return `${nextProject.kind} App ${nextProject.name}`;
    } else {
      return 'Next Project unkown';
    }
  }

  public previousProjectLinkTitle(currentProject: Project): string {
    const previousProject = this.nextProject(currentProject);

    if (previousProject) {
      return `${previousProject.kind} App ${previousProject.name}`;
    } else {
      return 'Next Project unkown';
    }
  }

  public previousProject(project: Project): Project | undefined {
    if (this.project) {
      return this.projectService.previousProjectOfProject(project);
    }
    return undefined;
  }

  public nextProject(project: Project): Project | undefined {
    if (this.project) {
      return this.projectService.nextProjectOfProject(project);
    }
    return undefined;
  }

  public showPreviousProject(project: Project) {
    const previousProject = this.previousProject(project);
    if (previousProject) {
      this.shouldShowLoadingSpinner = true;
      this.router.navigate(['/work', previousProject.linkName]);
    }
  }

  public showNextProject(project: Project) {
    const nextProject = this.nextProject(project);
    if (nextProject) {
      this.shouldShowLoadingSpinner = true;
      this.router.navigate(['/work', nextProject.linkName]);
    }
  }

  public imageWasLoaded() {
    this.shouldShowLoadingSpinner = false;
  }
}
