<div class="container">
  <h2>About the Project</h2>
  <p class="lead">
    <!-- Entwicklung einer iOS-App für Medikationspläne zwischen Patienten, Ärzten und Apotheken als neue eHealth-Lösung. Die Lösung deckt den gesamten Prozess ab - von der Verschreibung über die Beschaffung von Medikamenten bis hin zur Abrechnung. Gekoppelt mit einer verteilten Blockchain-Umgebung auf Ethereum-Basis inkl. Smart Contracts als vertrauenswürdige Schicht. -->
    Development of an iOS app for medication plans between patients, doctors and pharmacies as a new eHealth solution. The solution covers the entire process - from prescription to medication procurement to billing. Coupled with a distributed blockchain environment based on Ethereum including smart contracts as a trusted layer.
  </p>

  <h3>The problem</h3>
  <!-- <p>Medikamentöse Behandlung in Deutschland ist ein analoger und ineffizienter Prozess zwischen Patienten, Ärzten, Krankenhäusern, Apotheken und der Krankenversicherung. Lediglich 6,5% der Menschen, die regelmäßig Medikamente einnehmen, haben heute einen korrekten Medikationsplan. Rezepte gibt es nur in Papierform. Diese können gefälscht werden, können verloren gehen und müssen immer wieder neu ausgestellt werden.</p> -->
  <p>Medication treatment in Germany is an analogous and inefficient process between patients, physicians, hospitals, pharmacies and health insurance. Only 6.5% of people who take medication regularly have a correct medication plan today. Prescriptions exist only in paper form. These can be forged, can be lost and have to be reissued again and again.</p>

  <div class="mt-64"></div>

  <div class="row">
    <div class="col-md-8 offset-md-2">
      <figure class="figure">
        <img src="assets/images/projects/pil-the-problem.png" class="figure-img img-fluid rounded" alt="No trusted layer between patien, doctor, pharmacy and health insurance">
        <figcaption class="figure-caption text-center">The problem to solve | &copy; metafinanz</figcaption>
      </figure>
    </div>
  </div>

  <h3>The solution</h3>
  <!-- <p>Basis der Lösung bietet ein Smart Contract innerhalb einer Blockchain. Geht der Patient zu einem Arzt, erhält dieser Zugriff auf den Medikationsplan des Patienten durch eineentsprechende Datenfreigabe. Die Einsicht erfolgt über das Web-Frontend der Pillenfee. Der Arzt kann nun ein Medikament zur Behandlung wählen. Dabei findet automatisch eine Wechselwirkungsprüfung  zwischen bisherigen Medikamenten und dem neuen Medikament statt. Wenn der Arzt das Medikament ausstellt, wird für den Patienten ein Smart Contract in der Blockchain erstellt, ein eRezept. Der Patient kann jetzt das eRezept in jeder teilnehmenden Apotheke einlösen. Die Ausgabe des Medikamentes bewirkt eine Aktualisierung des Smart Contracts. Dadurch wird verhindert, dass der Patient ein eRezept zweimal einlösen kann. Wenn das Medikament aufgebraucht ist, kann er über die App beim Arzt ein neues eRezept anfordern. </p> -->
  <p>The solution is based on a smart contract within a blockchain. If the patient goes to a doctor, he or she receives access to the patient's medication plan through a corresponding data release. Access is provided via the Pillenfee web front end. The physician can now select a medication for treatment. An interaction check is automatically performed between the previous medication and the new medication. When the doctor issues the medication, a smart contract is created in the blockchain for the patient, an eReceipt. The patient can now fill the eReceipt at any participating pharmacy. Dispensing the medication causes the smart contract to be updated. This prevents the patient from redeeming an ePrescription twice. When the medication is used up, he can request a new eReceipt from the doctor via the app. </p>

  <div class="row">
    <div class="col-md-8 offset-md-2">
      <figure class="figure">
        <img src="assets/images/projects/pil-the-solution.png" class="figure-img img-fluid rounded" alt="Trusted layer between patien, doctor, pharmacy and health insurance">
        <figcaption class="figure-caption text-center">The solution: a trusted layer | &copy; metafinanz</figcaption>
      </figure>
    </div>
  </div>


</div>
