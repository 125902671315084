<section class="container my-7">
  <div class="row">

    <div class="col-12 col-lg-6 order-last order-lg-first d-flex flex-column justify-content-start align-items-center slide-in-from-left">
      <div class="pt-6 pt-lg-0">
        <h1 class="display-4 text-dark"><strong class="fw-bold">{{ 'about.HEADLINE' | translate }}</strong></h1>
        <p class="lead mt-1"
           [innerHTML]="'about.LEAD' | translate">
        </p>

        <div class="pt-3">
          <p [innerHTML]="'about.DESCRIPTION_1' | translate"></p>
          <p [innerHTML]="'about.DESCRIPTION_2' | translate"></p>
        </div>

        <div class="pt-4">
          <p [innerHTML]="'about.CV_PROMPT' | translate"></p>
          <app-cv-download-button [btnClass]="'btn btn-primary btn-fs-large'"></app-cv-download-button>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-6 order-first order-lg-last slide-in-from-right">
      <img id="hero-image" src="../../assets/images/felix-schmidt-smiling-during-programming-an-ios-application.webp" alt="Felix Schmidt is smiling while iOS development" />
    </div>
  </div>
</section>

<section id="skills" class="inner-shadow bg-elevated-1-light py-7">
  <div class="text-center pb-4">
    <h2 class="display-5 fw-bold text-center">{{ 'about.SKILLS_HEADLINE' | translate }}</h2>
    <p class="lead" [innerHTML]="'about.SKILLS_LEAD' | translate"></p>
  </div>
  <div class="container">
    <div class="row">

      <div class="col-12 col-lg-4 order-2 order-lg-1">
        <h3 class="skill-focus">{{ 'about.SKILLS_DEVELOPMENT' | translate }}</h3>
        <h4 class="skill-sub-focus">{{ 'about.SKILLS_IOS' | translate }}</h4>
        <ul class="fs-list">
          <li *ngFor="let item of ( 'about.SKILLS_IOS_ITEMS' | translate )">{{ item }}</li>
        </ul>
        <h4 class="skill-sub-focus">{{ 'about.SKILLS_WEB' | translate }}</h4>
        <ul class="fs-list">
          <li *ngFor="let item of ( 'about.SKILLS_WEB_ITEMS' | translate )">{{ item }}</li>
        </ul>
      </div>

      <div class="col-12 col-lg-4 order-1 order-lg-2 d-flex justify-content-center align-items-center py-4">
        <img class="skill-focus-pie-chart" src="../../assets/images/skill-focus-pie-chart.svg" alt="Skill focus as a pie chart">
      </div>

      <div class="col-12 col-lg-4 order-3">
        <h3 class="skill-focus" [innerHTML]="'about.SKILLS_DESIGN' | translate"></h3>
        <ul class="fs-list mt-3">
          <li *ngFor="let item of ( 'about.SKILLS_DESIGN_ITEMS' | translate )">{{ item }}</li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section id="random-facts" class="inner-shadow fscolor-bg-elevated-3-light py-7">
  <div class="container">
    <div class="row">

      <div class="col-xs-12 col-lg-6 d-flex flex-column justify-content-center align-items-center">
        <img class="rounded" src="../../assets/images/felix-schmidt-smiling-with-cup-behind-macbook.webp" loading="lazy" alt="Felix Schmidt is programming Swift and thinking with a coffee in his hands about a solution for the problem." />
      </div>

      <div class="col-xs-12 col-lg-6 pt-5 d-flex flex-column justify-content-center align-items-stretch align-items-sm-center">
        <article class="text-center text-lg-left">
          <h2 class="text-dark" [innerHTML]="'about.RANDOM_FACTS_HEADLINE' | translate"></h2>
          <ul class="fs-list text-dark">
            <li *ngFor="let fact of ( 'about.RANDOM_FACTS_ITEMS' | translate )">{{ fact }}</li>
          </ul>
        </article>
      </div>
    </div>
  </div>
</section>

<app-what-do-next [options]="{work: true, contact: true, socialConnect: true}"></app-what-do-next>
