import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectService } from '../shared/service/project.service';
import { appEnvironmentAsText } from '../core/common.utils';
import { Subscription } from 'rxjs';
import { Project } from './../model/model.vo';
import { LoadingIndicatorComponent } from '../shared/view/loading-indicator/loading-indicator.component';
import { AddOrEditProjectComponent } from './projects/add-or-edit-project.component';
import { CvUploadComponent } from './cv-upload/cv-upload.component';
import { AngularEnvironmentComponent } from './angular-environment/angular-environment.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    LoadingIndicatorComponent,
    AddOrEditProjectComponent,
    CvUploadComponent,
    AngularEnvironmentComponent
  ]
})
export class DashboardComponent implements OnInit, OnDestroy {
  projects: Project[] = null;
  isLoading = true;
  appEnvironmentText = appEnvironmentAsText();

  constructor(public projectService: ProjectService) {}

  ngOnInit() {
    this.subscriptions.add(
      this.projectService.projects$.subscribe({
        next: projects => { this.projects = projects; this.isLoading = false },
        error: error => { console.error(error); this.isLoading = false },
      })
    );
  }

  ngOnDestroy(): void {
      this.subscriptions.unsubscribe();
  }

  private subscriptions = new Subscription();
}
