<div class="container-fluid">
  <div class="row p-0 p-lg-1">
    <div class="col mx-lg-2 my-2 my-lg-1 py-6 d-flex flex-column justify-content-start align-items-center gap-2 promo-item"
         [style]="{
            'background-image': 'url(' + model.backgroundImage + '), ' + model.backgroundGradient,
            'cursor': (model.target ? 'pointer' : 'default')
          }">
      <h2 class="title">{{ model.title | translate }}</h2>
      <p class="lead" [innerHTML]="model.subtitle | translate"></p>
    </div>
  </div>
</div>
