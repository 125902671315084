import { Directive, ElementRef, HostListener, Input } from '@angular/core';

/**
 * ddd
 * ```html
 *
 * // Default Fallback Image
 * <img src="image_url" appImgFallback />
 *
 * // Fallback Image URL manually
 * <img src="image_url" appImgFallback="fallback_image_url" />
 *
 * // Fallback Image URL by variable of component class
 * <img src="image_url" [appImgFallback]="some_variable_in_component" />
 *
 * // Fallback Image URL by `ImgFallbackImage` type.
 * // Don't forget to store a enum reference in component class like
 * // `public imgFallbackImage = ImgFallbackImage;`
 * <img src="image_url" [appImgFallback]="imgFallbackImage.projectThumbnail" />
 * ```
 */
@Directive({
  selector: 'img[appImgFallback]'
})
export class ImgFallbackDirective {

  @Input() appImgFallback: string;

  constructor(private elementRef: ElementRef) { }

  @HostListener('error')
  loadFallbackError() {
    const element: HTMLImageElement = <HTMLImageElement>this.elementRef.nativeElement;
    element.src = this.fallbackImage();
  }

  private fallbackImage() {
    switch (this.appImgFallback) {
      case ImgFallbackImage.projectThumbnail:
        return '../../../assets/images/project-404.svg';
      default:
        return '../../../assets/images/img-fallback-default-image.webp';
    }
  }
}

export enum ImgFallbackImage {
  projectThumbnail = 'projectThumbnail'
}
