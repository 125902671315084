import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { isDef } from 'src/app/core/common.utils';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, RouterModule]
})
export class AuthComponent implements OnInit {

  form = new FormGroup({
    "email": new FormControl<string>('', [Validators.required, Validators.email]),
    "password": new FormControl<string>('', Validators.required)
  });

  showLoginWasFailedText = false;

  constructor(
    public authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  onSubmit() {
    console.log(this.form);

    const email = this.form.value['email'] as string;
    const password = this.form.value['password'] as string;
    this.authService.loginEmail(email, password)
      .then(() => {
        console.log('Should be authenticated....');
        console.log(this.authService.isAuthenticated);

        if (this.authService.isAuthenticated) {
          this.showLoginWasFailedText = false;
          this.showNextRouteIfNeeded();

        } else {
          this.showLoginWasFailedText = true;
        }
      });
  }

  markEmailInputInvalid(): boolean {
    const emailFormControl = this.form.get('email');

    if (emailFormControl.value) {
      return !this.form.get('email').valid;
    } else {
      return false;
    }
  }

  private showNextRouteIfNeeded() {
    const nextRouteBeforeAuthGuard = localStorage.getItem('nextRouteBeforeAuthGuard');
    if (isDef(nextRouteBeforeAuthGuard)) {
      this.router.navigate([nextRouteBeforeAuthGuard]).then(onFulfilled => {
        if (onFulfilled) {
          localStorage.removeItem('nextRouteBeforeAuthGuard');
        }
      });
    }
  }
}
