@if (cvInformation$ | async; as cvInformation) {
  <button
    [class]="btnClass"
    (click)="openCV(cvInformation)"
  >
  @if (showIcon) {
    <fa-icon [icon]="icons.fileAlt"
             class="me-2">
    </fa-icon>
  }
    {{ title }}
  </button>

  <aside class="text-information fade-in mt-2">
    <span>{{ 'cv_download_button.localization' | translate }}{{ cvInformation.locale }} ({{ cvInformation.language }}). </span>
    <a
      [routerLink]="['/resume']"
      routerLinkActive="router-link-active">{{ 'cv_download_button.other_versions' | translate }}
    </a>
  </aside>
}
