<div class="container-fluid">
  <header class="text-center my-5 fade-in duration-2">
    <h1>Recent Work & Projects</h1>
    @if(!isError) {
      <p>2013 &mdash; now</p>
    }
  </header>

  @if(isLoading) {
    <app-loading-indicator></app-loading-indicator>
  }
  @if(isError) {
    <app-something-went-wrong [body]="'A lot of amazing work and projects couldn\'t be loaded.'"
                              (onButtonClick)="loadProjects()"
    >
    </app-something-went-wrong>
  }

  <section id="work-gallery"
           class="row d-flex flex-wrap justify-content-start align-items-start gx-0 fade-in duration-2 delay-2">

    @for(project of projects; track project.shortName) {
      <div class="project-thumbnail"
          [ngClass]="project.canBeDisplayed ? 'fade-in duration-2' : 'hide'">

          <img [src]="project.workItemThumbnailImageURL" async
               [ngClass]="project.hovered ? 'zoom' : ''"
               (mouseenter)="project.hovered = true"
               (mouseleave)="project.hovered = false"
               (load)="project.canBeDisplayed = true"
               (click)="showProjectDetails(project)" />

          <div class="project-description-wrapper d-flex justify-content-center align-items-center"
               (mouseenter)="project.hovered = true"
               (mouseleave)="project.hovered = false"
               (click)="showProjectDetails(project)">

            <div class="project-description">
              <div class="fade-in duration-2">
                <div class="d-flex flex-md-column justify-content-center align-items-center gap-3">
                     <h2 class="project-name order-1 order-md-2">{{project.name}}</h2>
                     <div class="project-kind order-2 order-md-1 bg-dark">{{project.kind}}</div>
                </div>
                <a class="view-project-details">&mdash; view details &mdash;</a>
              </div>
            </div>
          </div>
        </div>
    }
  </section>

  <div class="container mt-5">
    <hr>
  </div>
</div>
