<div class="py-4">
  <h3>CV Upload</h3>

  <p class="lead">Verfügbare Versionen</p>

  <ul *ngIf="(cvInformations$ | async) as cvInformations" class="list-group w-50">
    <li *ngFor="let cvInforation of cvInformations"
        class="list-group-item d-flex justify-content-between align-items-center">
        <div>
          <span>{{ cvInforation.language }} </span>
          <span>-> {{ cvInforation.locale }}</span>
        </div>
        <button (click)="openCV(cvInforation)"
                class="btn btn-small">
          <code>Version {{ cvInforation.version }}</code>
        </button>
    </li>
  </ul>

  <div class="my-3">
    <p class="lead">Neue Version hochladen</p>
    <div class="d-flex justify-content-begin align-items-center gap-3">
      <input
        class="form-control"
        type="file"
        id="inputCVUpload"
        (change)="uploadCV($event)"
      >
      <span *ngIf="( fbStorageService.cvUploadProgress$ | async ) as uploadProgress;">
        {{ uploadProgress | number:'1.0-0' }}% Uploading
      </span>

      <!-- <button
        *ngIf="(cvInformation$ | async) as cvInformation"
        [disabled]="isUploadingCV || !cvInformation.downloadURL"
        class="btn btn-small btn-info"
        (click)="openCV(cvInformation)"
      >
        <div class="d-flex justify-content-space align-items-center gap-2">
          <span *ngIf="( fbStorageService.cvUploadProgress$ | async ) as uploadProgress; else openCVTitle">
            {{ uploadProgress | number:'1.0-0' }}% Uploading
          </span>
          <ng-template #openCVTitle>
            <span>Open CV</span>
          </ng-template>
        </div>
      </button> -->
    </div>
  </div>

</div>
