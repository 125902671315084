import { LocalStorageService } from './shared/service/local-storage.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'felix-schmidt-web-app';

  isCookiesAccepted: boolean = this.localStorageService.getItem('cookiesAccepted') === 'true' || false;

  constructor(private localStorageService: LocalStorageService) {

  }

  ngOnInit() {
    this.localStorageService.watchLocalStorage().subscribe((change: string) => {
      console.log(this.localStorageService.getItem('cookiesAccepted'));
      this.isCookiesAccepted = this.localStorageService.getItem('cookiesAccepted') === 'true';
    });
  }
}
