import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-imprint-and-data-privacy',
  templateUrl: './imprint-and-data-privacy.component.html',
  styleUrls: ['./imprint-and-data-privacy.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class ImprintAndDataPrivacyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
