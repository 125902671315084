import { isProduction } from 'src/app/core/common.utils';
import { Observable, finalize, from, lastValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FirebaseFirestoreDatabaseService } from './firebase-firestore-database.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseStorageService {

  private projectImagesPath = isProduction() ? 'projects/' : 'projects-dev/';
  private workItemThumbnailFolder = 'work-item-thumbnails/';
  private filesFolder = isProduction() ? 'files' : 'files-dev';

  cvUploadProgress$: Observable<number | null> = null;

  constructor(
    private fbStorage: AngularFireStorage,
    private fbDatabaseService: FirebaseFirestoreDatabaseService,
  ) {}

  /**
   * Stores a project image
   * @param imageName should be project link name
   * @param image should be SVG type
   */
  public storeProjectImage(imageName: string, image: any) {
    const fileExtension = image.name.split('.').pop();
    return this.fbStorage.upload(
      this.projectImagesPath + imageName + '.' + fileExtension,
      image
    );
  }

  /**
   * Returns the image URL from the given project link name
   * @param projectLinkName project link name
   */
  public fetchProjectImageURL(projectLinkName: string): Observable<any> {
    const fileName = projectLinkName + '.svg';
    return this.fbStorage.ref(this.projectImagesPath + fileName).getDownloadURL();
  }

  public getProjectWorkThumbnailImageURL(projectShortName: string) {
    const fileName = 'work-thumbnail-' + projectShortName + '@0.5x.webp';
    return this.fbStorage
      .ref(this.projectImagesPath + this.workItemThumbnailFolder + fileName)
      .getDownloadURL();
  }

  uploadCV(cvFile: File): Promise<{downloadURL: string}> {
    return new Promise((resolve, reject) => {
      const cvFileVersionMatch = cvFile.name.match(/(\d+\.\d+\.\d+)\.pdf$/)
      const cvVersion = cvFileVersionMatch ? cvFileVersionMatch[1] : 'unknown'
      const filePath = `${this.filesFolder}/cv/Felix-Schmidt-iOS-Developer-Freelance-CV.pdf`
      const fileRef = this.fbStorage.ref(filePath)
      const storageUploadTask = this.fbStorage.upload(
        filePath,
        cvFile,
        {
          contentType: 'application/pdf',
          customMetadata: {
            copyright: `Copyright © ${new Date().getFullYear()} Felix Schmidt Software Engineering, represented by Felix Schmidt`
          }
        },
      )


      this.cvUploadProgress$ = from(storageUploadTask.percentageChanges())

      storageUploadTask.snapshotChanges()
        .pipe(
          finalize(async () => {
            const downloadURL = await lastValueFrom(fileRef.getDownloadURL())

            // Save storage information into database
            this.fbDatabaseService.updateCVInformation({
              version: cvVersion,
              downloadURL: downloadURL,
            })
            .then(() => resolve({ downloadURL: downloadURL }))
            .catch((error) => reject(error))
            .finally(this.cvUploadProgress$ = null)
          })
        )
        .subscribe()
    })
  }
}

/**
 * # Project Images
 *
 * ## Work Project Promo
 * projects[-dev]/<linkName>/work-promo-<shortName>-large@2x.webp
 * Example
 * projects/corona-warn-app/work-promo-cwa-large@2x.webp
 *
 * ## Project Thumbnail
 * projects[-dev]/<linkName>/project-thumbnail-<shortName>.svg
 * Example
 * projects-dev/pillenfee/project-thumbnail-pil.svg
 *
 * ## Project Previews
 * projects[-dev]/<linkName>/project-preview-<shortName>-<##>.webp
 * Example
 * projects-dev/tk-safe/project-preview-ega-04.webp
 */
