<section class="inner-shadow
                fscolor-bg-elevated-2-light
                py-7">
  <div class="container">
    <div class="row">

      <div class="col-xs-12 col-lg-7
                  d-flex flex-column
                  justify-content-center align-items-center
                  text-center">
        <h2 class="h3">What do you want to do now?</h2>
        <p class="lead">Here are a few suggestions for you:</p>
      </div>

      <div class="col-xs-12 col-lg-5
                  d-flex flex-column
                  justify-content-center align-items-center
                  gap-4
                  pt-5">

        <button class="btn btn-outline-primary btn-fs-large"
                routerLink="/about"
                *ngIf="options.about">Read more about me</button>

        <button class="btn btn-outline-primary btn-fs-large"
                routerLink="/work"
                *ngIf="options.work">See my work</button>

        <button class="btn btn-primary btn-fs-large"
                routerLink="/contact"
                *ngIf="options.contact">Get in touch</button>

        <div *ngIf="options.socialConnect">
          <p>or connect with me</p>
          <div class="d-flex
                      justify-content-center align-items-center
                      gap-3">

            <a class="social-media-icon"
               href="{{ referrals.linkedin.url }}"
               title="Connect with me on LinkedIn">
              <fa-icon [icon]="icons.linkedinIn"></fa-icon>
            </a>

            <a class="social-media-icon"
               href="{{ referrals.xing.url }}"
               title="Connect with me on Xing">
              <fa-icon [icon]="icons.xing"></fa-icon>
            </a>

            <a class="social-media-icon"
               href="{{ referrals.github.url }}"
               title="Follow me on GitHub">
              <fa-icon [icon]="icons.gitHub"></fa-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
