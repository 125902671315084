import { environment } from './../../environments/environment';

export function isDef<T>(value: T | undefined | null): value is T {
  return (value as T) !== undefined && (value as T) !== null;
}

export function isProduction(): boolean {
  return environment.production === true;
}

export function appEnvironmentAsText(): string {
  return isProduction() === true ? 'Prod Environment' : 'Dev Environment';
}

// Extension string
declare global {
  interface String { makeLowerCaseDashed(): string; }
}

String.prototype.makeLowerCaseDashed = function (): string {
  return String(this).toLowerCase().replace(/\s/g, '\-');
};
