<div class="container-fluid">
  <div class="row p-0 p-lg-1">
    @for (model of promoItemModels; track model.title) {
      <div class="col-lg bg-light mx-lg-2 my-2 py-6 d-flex flex-column align-items-center gap-2 promo-item"
           [class]="model.reverse ? 'justify-content-end' : 'justify-content-start'"
           [ngStyle]="{
              'background-image': 'url(' + model.backgroundImage + ')' + (model.backgroundGradient ? ', ' + model.backgroundGradient : ''),
              'cursor': (model.target ? 'pointer' : 'default')
            }">
        <h2 class="title">{{ model.title | translate }}</h2>
        <p class="lead text-center" [innerHTML]="model.subtitle | translate"></p>
      </div>
    }
  </div>
</div>
