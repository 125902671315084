import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  private _countryCodeSubject = new BehaviorSubject<CountryCode | null>(null)
  countryCode$ = this._countryCodeSubject.asObservable()

  constructor() {
    this.fetchCountryCode()
  }

  get countryCode(): CountryCode | null {
    return this._countryCodeSubject.value
  }

  private async fetchCountryCode(): Promise<void> {
    try {
      const response = await fetch('https://api.country.is')
      const data = await response.json()

      const county = Object.values(CountryCode).includes(data.country)
        ? (data.country as CountryCode)
        : null

      this._countryCodeSubject.next(county)
    } catch (error) {
      console.log('Coutry code could not be determined. Maybe VPN active?')
      this._countryCodeSubject.next(null)
    }
  }
}

export enum CountryCode {
  Germany = 'DE',
  Switzerland = 'CH'
}
