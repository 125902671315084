export interface ReferralItem {
  name: string
  url: string
};

export interface ReferralsDataSource {
  // Affiliate
  amexgold: ReferralItem
  americanexpressgold: ReferralItem
  binance: ReferralItem
  bitfinex: ReferralItem
  bittrex: ReferralItem
  cro: ReferralItem
  cryptocom: ReferralItem
  payback: ReferralItem
  // Social Network
  github: ReferralItem
  freelancermap: ReferralItem,
  linkedin: ReferralItem
  stackoverflow: ReferralItem
  twitter: ReferralItem
  xing: ReferralItem
}

export const referrals: ReferralsDataSource = {
  amexgold: {
    name: 'amexgold',
    url: 'https://www.amex.de/freunde/fELIXSwRGp?XLINK=MYCP'
  },
  americanexpressgold: {
    name: 'americanexpressgold',
    url: 'https://www.amex.de/freunde/fELIXSwRGp?XLINK=MYCP'
  },
  binance: {
    name: 'binance',
    url: 'https://accounts.binance.com/en/register?ref=109535911'
  },
  bitfinex: {
    name: 'bitfinex',
    url: 'https://bitfinex.com/?refcode=WeBxbEdHt'
  },
  bittrex: {
    name: 'bittrex',
    url: 'https://bittrex.com/account/register?referralCode=WFF-44T-NBA'
  },
  cro: {
    name: 'cro',
    url: 'https://platinum.crypto.com/r/d78j969k63'
  },
  cryptocom: {
    name: 'cryptocom',
    url: 'https://platinum.crypto.com/r/d78j969k63'
  },
  payback: {
    name: 'payback',
    url: 'https://www.payback.de/anmelden/freunde-werben?mgm-ref=b8969f86-6464-4d21-8858-be4ee73cfe09&excid=mgm&incid=01_20172_000'
  },
  github: {
    name: 'github',
    url: 'https://github.com/flxschmidt'
  },
  freelancermap: {
    name: 'freelancermap',
    url: 'https://www.freelancermap.de/freelancer-verzeichnis/profile/entwicklung/219599-profil-felix-schmidt-senior-ios-developer-creative-minder.html'
  },
  linkedin: {
    name: 'linkedin',
    url: 'https://www.linkedin.com/in/felix-schmidt-ios-developer/'
  },
  stackoverflow: {
    name: 'stackoverflow',
    url: 'https://stackoverflow.com/users/7007818/schmidtfx'
  },
  twitter: {
    name: 'twitter',
    url: 'https://twitter.com/flxschmidt'
  },
  xing: {
    name: 'xing',
    url: 'https://www.xing.com/profile/Felix_Schmidt17'
   }
};
