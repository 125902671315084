<div class="container text-center">
  <header class="fade-in duration-2 mt-32 mb-32">
    <h1>Maintenance time ...</h1>
  </header>

  <p class=" fade-in duration-3 mt-32 mb-32">
    My site will be completely rebuild at this time.
  </p>

  <div class="row fade-in duration-4 mt-32 mb-32">
    <p class="col-xs-12 col-sm-8 offset-sm-2">
      I am a highly motivated individual developer with strong iOS skills combined with UX Design enthusiasm, attention to detail and a solid background of successful comprehensive software projects. A studied business computer scientist with more than 10 years of professional experience, who loves to create great digital products.
      <br><br>

      <a href="mailto:hello@felixschmidt.software">hello&#64;felixschmidt.software</a>
      <br><br>
      <a href="https://www.freelancermap.de/freelancer-verzeichnis/profile/entwicklung/219599-profil-felix-schmidt-ios-developer-creative-mind.html)" target="_blank" title="Profil von Felix Schmidt auf www.freelancermap.de"><img src="https://www.freelancermap.de/images/widgets/button/findenSieMich.png"></a>
    </p>
  </div>
</div>
