<footer
  id="footer"
  class="py-5"
>
  <div class="container">
    <div class="row">
      <div class="col-md-4 text-center text-md-start">
        <a
          href="https://app-entwickler-verzeichnis.de"
          title="iOS, Web Entwicklung Felix Schmidt bei Görlitz (iPhone, iPad)"
        >
          <img
            src="https://app-entwickler-verzeichnis.de/images/corporate/app-entwickler-stempel.png"
            alt="Icon Mitglied App-Entwickler-Verzeichnis"
            width="80"
            height="80"
          />
        </a>
      </div>

      <div class="col-md-8 text-center text-md-end">
        <aside class="text-information pt-3">
          Copyright &copy; 2020 - {{currentYear()}} Felix Schmidt and respective copyright holders.
        </aside>
        <div class="mt-2">
          <a
            routerLink="/imprint-and-data-privacy"
            routerLinkActive="active"
            class="text-decoration-none"
          >
            Imprint & Data Privacy
          </a>
        </div>
      </div>
    </div>
  </div>
</footer>
