import { Directive, ElementRef, Renderer2, Input, OnInit, OnDestroy } from '@angular/core';

@Directive({
  selector: '[animateIfInViewport]',
})
export class AnimateIfInViewportDirective implements OnInit, OnDestroy {
  @Input('animateIfInViewport') animationCustomConfig: {
    style?: string;
    delay?: number
  } = {};

  private observer: IntersectionObserver;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    // Merge default values with the provided config
    const animationConfig = { style: 'fadeIn', delay: 0, ...this.animationCustomConfig };

    const options = {
      root: null,
      threshold: 0.1
    };

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.renderer.addClass(this.el.nativeElement, 'animate__animated');
          this.renderer.addClass(this.el.nativeElement, 'animate__' + animationConfig.style);

          if (animationConfig.delay > 0) {
            this.renderer.setStyle(this.el.nativeElement, 'animation-delay', `${animationConfig.delay}ms`);
          }
          this.observer.unobserve(this.el.nativeElement);
        }
      });
    }, options);

    this.observer.observe(this.el.nativeElement);
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}

