<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ modalTitle }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">

    <form [formGroup]="projectFormGroup">

      <div class="container-fluid">
        <div class="row">
          <div class="col-6">
            <img [src]="project.imageURL" async
                 *ngIf="project" />
          </div>

          <div class="col-6">
            <div class="form-group">
              <label class="form-label"
                     for="projectShortNameInput">ShortName</label>
              <input type="text"
                     class="form-control"
                     id="projectShortNameInput"
                     formControlName="shortName"
                     placeholder="EGA"/>
            </div>

            <div class="form-group">
              <label class="form-label"
                     for="projectNameInput">Name</label>
              <input type="text"
                     class="form-control"
                     id="projectNameInput"
                     formControlName="name"
                     placeholder="TK Safe" />
            </div>
            <p><small>LinkName: {{ linkName }}</small></p>

            <div class="form-group">
              <label class="form-label"
                     for="projectKindInput">Kind</label>
              <select class="form-control"
                      id="projectKindInput"
                      formControlName="kind">
                <option [ngValue]="enum.value" *ngFor="let enum of KindOfProduct | keyvalue">{{enum.value}}</option>
              </select>
            </div>

            <div class="form-group">
              <label class="form-label"
                     for="projectRoleInput">Role</label>
              <input type="text"
                     class="form-control"
                     id="projectRoleInput"
                     formControlName="role"
                     placeholder="Senior iOS Developer" />
            </div>
          </div>
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col-6">
          <div class="form-group
                      mb-3">
            <label class="form-label"
                   for="projectClientInput">Client</label>
            <input type="text"
                   class="form-control"
                   id="projectClientInput"
                   formControlName="client"
                   placeholder="Allianz Krankenversicherungs AG" />
          </div>

          <div class="form-group
                      mb-3">
            <label class="form-label"
                   for="projectIndustryInput">Industry</label>
            <input type="text"
                   class="form-control"
                   id="projectIndustryInput"
                   formControlName="industry"
                   placeholder="Versicherungen" />
          </div>
        </div>
        <div class="col-6">
          <div class="form-group
                      mb-3">
            <label class="form-label"
                   for="projectStartInput">Start (YYYY-MM-DD)</label>
            <input type="text"
                   class="form-control"
                   id="projectStartInput"
                   formControlName="start"
                   placeholder="2020-02-01" />
          </div>

          <div class="form-group
                      mb-3">
            <label class="form-label"
                   for="projectEndInput">End (YYYY-MM-DD)</label>
            <input type="text"
                   class="form-control"
                   id="projectEndInput"
                   formControlName="end"
                   placeholder="opt. 2020-12-31"/>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <ul ngbNav #nav="ngbNav" [(activeId)]="activeProjectDescriptionTab" class="nav-tabs">
            <li [ngbNavItem]="1">
              <a ngbNavLink>Standard</a>
              <ng-template ngbNavContent>
                <p><small>Wird nur angezeigt, solange die Custom-Project leer ist.</small></p>
                <div class="form-group
                            mb-3">
                  <label class="form-label"
                         for="projectDescriptionInput">Description</label>
                  <textarea type="text"
                         class="form-control"
                         id="projectDescriptionInput"
                         formControlName="description"
                         rows="4"></textarea>
                </div>

                <div class="form-group
                            mb-3">
                  <label class="form-label"
                         for="projectDescriptionTechnicalInput">Technichal Description</label>
                  <textarea type="text"
                         class="form-control"
                         id="projectDescriptionTechnicalInput"
                         formControlName="descriptionTechnical"
                         rows="8"></textarea>
                </div>

                <div formGroupName="moreInformations">
                  <div class="form-group
                              mb-3">
                    <label class="form-label"
                           for="projectMoreInformationTitle">More Informations (Title)</label>
                    <input type="text"
                           class="form-control"
                           id="projectMoreInformationTitle"
                           formControlName="title"/>
                  </div>

                  <div class="form-group
                              mb-3">
                    <label class="form-label"
                           for="projectMoreInformationURL">More Informations (URL)</label>
                    <input type="text"
                           class="form-control"
                           id="projectMoreInformationURL"
                           formControlName="url"/>
                  </div>
                </div>

              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink>Custom</a>
              <ng-template ngbNavContent>
                <div class="form-group">
                  <label class="form-label"
                         for="projectCustomProjectSite">Custom Project Site <code>HTML Markup</code></label>
                  <textarea type="text"
                         class="form-control"
                         id="projectCustomProjectSite"
                         formControlName="customProjectSite"
                         rows="16"
                         placeholder="HTML"></textarea>
                </div>
              </ng-template>
            </li>
          </ul>

          <div [ngbNavOutlet]="nav" class="mt-2"></div>

        </div>
      </div>


      <hr>

      <div class="form-group">
        <label class="form-label"
               for="projectTagsInput">Tags</label>
        <input type="text"
               class="form-control"
               id="projectTagsInput"
               formControlName="tags"
               placeholder="iOS, Swift" />
      </div>

      <hr>
      <p><strong>New project image (preview)</strong></p>
      <div class="text-center">
        <img [src]="projectImagePreview"
             width="350px"
             height="250px"
             (click)="imagePickerInput.click()"
             *ngIf="projectImagePreview">
      </div>
      <div class="form-group">
        <label class="form-label">Select file to upload</label>
        <input type="file"
               accept="image/*"
               class="form-control"
               #imagePickerInput
               (change)="previewProjectImage($event)">
      </div>
    </form>

    <div>
      <hr>
      <p><strong>Project Raw</strong></p>
      <pre>{{ project | json }}</pre>
    </div>

  </div>

  <div class="modal-footer">
    <div [ngSwitch]="modus">

      <!-- ADD PROJECT -->
      <button type="button"
              class="btn btn-md btn-success"
              *ngSwitchCase="'add'"
              [disabled]="isNetworkRequest"
              (click)="onAddProject()">
        <span class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
              *ngIf="isNetworkRequest"></span>
        {{ isNetworkRequest ? 'Adding ...' : 'Add' }}
      </button>

      <!-- EDIT PROJECT -->
      <button type="button"
              class="btn btn-md btn-success"
              *ngSwitchCase="'edit'"
              [disabled]="isNetworkRequest"
              (click)="onUpdateProject()">
        <span class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
              *ngIf="isNetworkRequest"></span>
        {{ isNetworkRequest ? 'Updating ...' : 'Update' }}
      </button>

    </div>

  </div>
</ng-template>

<div [ngSwitch]="modus">

<button class="btn btn-sm btn-link" [ngSwitch]="modus" (click)="open(content)">
  <span *ngSwitchCase="'add'">+ Add</span>
  <span *ngSwitchCase="'edit'">Edit</span>
</button>
