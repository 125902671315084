<section class="inner-shadow
                fscolor-bg-elevated-2-light
                py-7">
  <div class="container">
    <div class="row">

      <div class="col-xs-12 col-lg-7
                  d-flex flex-column
                  justify-content-center align-items-center
                  text-center">
        <h2 class="h3">{{ 'components.whatDoNext.HEADLINE' | translate }}</h2>
        <p class="lead">{{ 'components.whatDoNext.LEAD' | translate }}</p>
      </div>

      <div class="col-xs-12 col-lg-5
                  d-flex flex-column
                  justify-content-center align-items-center
                  gap-4
                  pt-5">

        <button class="btn btn-outline-primary btn-fs-large"
                routerLink="/about"
                *ngIf="options.about">{{ 'components.whatDoNext.OPTION_1' | translate }}</button>

        <button class="btn btn-outline-primary btn-fs-large"
                routerLink="/work"
                *ngIf="options.work">{{ 'components.whatDoNext.OPTION_2' | translate }}</button>

        <button class="btn btn-primary btn-fs-large"
                routerLink="/contact"
                *ngIf="options.contact">{{ 'components.whatDoNext.OPTION_3' | translate }}</button>

        <div *ngIf="options.socialConnect">
          <p>{{ 'components.whatDoNext.SOCIAL_CONNECT' | translate }}</p>
          <div class="d-flex
                      justify-content-center align-items-center
                      gap-3">

            <a class="social-media-icon text-secondary"
               href="{{ referrals.linkedin.url }}"
               title="{{ 'components.whatDoNext.LINKEDIN_TITLE' | translate }}">
              <fa-icon [icon]="icons.linkedinIn"></fa-icon>
            </a>

            <a class="social-media-icon text-secondary"
               href="{{ referrals.xing.url }}"
               title="{{ 'components.whatDoNext.XING_TITLE' | translate }}">
              <fa-icon [icon]="icons.xing"></fa-icon>
            </a>

            <a class="social-media-icon text-secondary"
               href="{{ referrals.github.url }}"
               title="{{ 'components.whatDoNext.GITHUB_TITLE' | translate }}">
              <fa-icon [icon]="icons.gitHub"></fa-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
