import { Injectable } from '@angular/core';
import { GoogleAuthProvider } from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(public angularFireAuth: AngularFireAuth) {
    this.angularFireAuth.onAuthStateChanged(firebaseUser => {
      if (firebaseUser) {
        localStorage.setItem('user', JSON.stringify(firebaseUser));
      } else {
        localStorage.removeItem('user');
      }
    });
  }

  get isAuthenticated(): boolean {
    const user = JSON.parse(localStorage.getItem('user')) as firebase.default.User;
    return user !== null;
  }

  async loginEmail(email: string, password: string) {
    await this.angularFireAuth.signInWithEmailAndPassword(email, password)
      .then( response => {
        localStorage.setItem('user', JSON.stringify(response.user));
      })
      .catch(error => {
        console.error(error);
      });
  }

  loginGoogle() {
    var googleAuthProvider = new GoogleAuthProvider();
    this.angularFireAuth.signInWithPopup(googleAuthProvider).catch(error => {
      console.error(error);
    });
  }

  logout() {
    this.angularFireAuth.signOut()
    .then(_ => {
      localStorage.removeItem('user');
    })
    .catch(error => {
      console.error(error);
    });
  }
}
