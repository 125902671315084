import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-imprint-and-data-privacy',
  templateUrl: './imprint-and-data-privacy.component.html',
  styleUrls: ['./imprint-and-data-privacy.component.scss']
})
export class ImprintAndDataPrivacyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
