import { PromoItemModel, PromoItemType } from "src/app/model/model.vo";

export const servicePromoItemModels: ServicesPromoItemInterface = {
  iOS: {
    type: PromoItemType.Hero,
    title: 'start.servicesPromo.iOS.TITLE',
    subtitle: 'start.servicesPromo.iOS.SUBTITLE',
    backgroundImage: '../../../assets/images/services/service-ios-development-promo-hero.webp',
    backgroundGradient: 'linear-gradient(to top, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%)',
    target: null
  },
  web: {
    type: PromoItemType.Pair,
    title: 'start.servicesPromo.web.TITLE',
    subtitle: 'start.servicesPromo.web.SUBTITLE',
    backgroundImage: '../../../assets/images/services/service-web-development-promo-small.webp',
    backgroundGradient: 'linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%)',
    target: null
  },
  design: {
    type: PromoItemType.Pair,
    title: 'start.servicesPromo.design.TITLE',
    subtitle: 'start.servicesPromo.design.SUBTITLE',
    backgroundImage: '../../../assets/images/services/service-design-promo-small.webp',
    reverse: true
  }
}

export interface ServicesPromoItemInterface {
  iOS: PromoItemModel
  web: PromoItemModel
  design: PromoItemModel
}
