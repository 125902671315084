<nav id="navigation"
     [ngClass]="toggleNav ? 'show' : ''">
  <div class="nav-content">
    <a routerLink="/hello"
       class="logo
              d-flex
              justify-content-between align-items-center
              gap-2">
      <img src="../../assets/images/felix-schmidt-software-logo.svg"
           alt="Felix Schmidt Software Engineering Logo"
           height="60px"
           width="auto">
    </a>
    <div class="nav-icon"
         (click)="toggleNav = !toggleNav">
      <div class="bar one"></div>
      <div class="bar two"></div>
      <div class="bar three"></div>
    </div>
    <div class="nav-links">
      <a routerLink="/hello"
         routerLinkActive="active"
         (click)="toggleNav = false">Hello</a>
      <a routerLink="/about"
         routerLinkActive="active"
         (click)="toggleNav = false">About</a>
      <a routerLink="/work"
         routerLinkActive="active"
         (click)="toggleNav = false">Work</a>
      <a routerLink="/contact"
         routerLinkActive="active"
         (click)="toggleNav = false">Contact</a>
      <a routerLink="/dashboard"
         routerLinkActive="active"
         *ngIf="authService.isAuthenticated"
         (click)="toggleNav = false">Dashboard</a>
      <a routerLink="/auth"
         routerLinkActive="active"
         *ngIf="authService.isAuthenticated"
         (click)="authService.logout()">Logout</a>
      <div class="social-media-icons">
        <p *ngIf="toggleNav" class="d-md-none mt-2 mb-0"><small>Visit me on further social networks <fa-icon [icon]="socialMediaIcons.externalLink" class="d-inline-block mx-1"></fa-icon></small></p>
        <div class="d-flex flex-wrap">
          <a class="social-media-icon"
             routerLink="/ref/{{ referrals.linkedin.name }}"
             title="Connect with me on LinkedIn"><fa-icon [icon]="socialMediaIcons.linkedIn"></fa-icon></a>
          <a class="social-media-icon"
             routerLink="/ref/{{ referrals.xing.name }}"
             title="Connect with me on Xing"><fa-icon [icon]="socialMediaIcons.xing"></fa-icon></a>
          <a class="social-media-icon"
             routerLink="/ref/{{ referrals.github.name }}"
             title="Follow me on Twitter"><fa-icon [icon]="socialMediaIcons.github"></fa-icon></a>
        </div>
      </div>
    </div>
  </div>
</nav>
