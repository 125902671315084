<div class="container">

  <h1>Authentication</h1>

  <div *ngIf="authService.angularFireAuth.user | async as user; else showLogin">
    <p>Authenticated as {{ user.email }}</p>
    <button class="btn btn-secondary btn-sm" (click)="authService.logout()">Logout</button>
  </div>

  <ng-template class="row text-left" #showLogin>
    <hr>

    <div class="col-xs-12 offset-md-2 col-md-8 offset-lg-3 col-lg-6">
      <h2 class="h4">By Email</h2>

      <div *ngIf="showLoginWasFailedText" class="mb-16">
        <small class="bg-warning">Ooops, Login fehlgeschlagen.</small>
      </div>

      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label for="emailInput">Email</label>
          <input formControlName="email"
                 [class.is-invalid]="markEmailInputInvalid()"
                 type="email"
                 class="form-control"
                 id="emailInput">
        </div>
        <div class="form-group">
          <label for="passwordInput">Password</label>
          <input formControlName="password"
                 type="password"
                 class="form-control"
                 id="passwordInput">
        </div>
        <button [disabled]="!form.valid"
                type="submit"
                class="btn btn-primary">Submit</button>
      </form>

    </div>
  </ng-template>
</div>



