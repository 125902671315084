<div class="cookie-hint">
  <div class="container">
    <div class="row">
      <div class="col-xc-12 col-md-10">
        <p>Diese Webseite verwendet Cookies zur Analyse Ihres Besuchs und Ihrer Aktivitäten auf unserer Webseite. Durch die weitere Nutzung dieser Webseite stimmen Sie der Verwendung von Cookies zu.</p>
      </div>

      <div class="col-xs-12 col-md-2 text-xs-center text-md-right">
        <button class="btn btn-success" (click)="acceptCookies()">verstanden</button>
        <button class="btn btn-link" routerLink="/imprint-and-data-privacy">Datenschutz</button>
      </div>
    </div>
  </div>
</div>
