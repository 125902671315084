<div class="container-fluid">
  <header class="text-center my-5 fade-in duration-2">
    <h1 class="display-5 text-dark" [innerHTML]="'work.HEADLINE' | translate"></h1>
    <p class="lead" [innerHTML]="'work.SUBHEADLINE' | translate"></p>
  </header>

  <!-- Loading indicator while projects are loading -->
  <ng-container *ngIf="!(projects$ | async); else workGallery">
    <app-loading-indicator></app-loading-indicator>
  </ng-container>

  <!-- Work gallery -->
  <ng-template #workGallery>
    <section id="work-gallery"
             class="row d-flex flex-wrap justify-content-start align-items-start gx-0 fade-in duration-2 delay-2">
      <div *ngFor="let project of projects$ | async" class="project-thumbnail fade-in duration-2"
           (click)="showProjectDetails(project)">
        <img [src]="project.workItemThumbnailImageURL" async
             [class]="project.hovered ? 'zoom' : ''"
             (mouseenter)="project.hovered = true"
             (mouseleave)="project.hovered = false"
             (click)="showProjectDetails(project)"
             [alt]="project.name + ' thumbnail image'"/>
        <div class="project-description-wrapper d-flex justify-content-center align-items-center"
             (mouseenter)="project.hovered = true"
             (mouseleave)="project.hovered = false"
             (click)="showProjectDetails(project)">
          <div class="project-description fade-in duration-2">
            <div class="d-flex flex-md-column justify-content-center align-items-center gap-3">
              <h2 class="project-name order-1 order-md-2">{{ project.name }}</h2>
              <div class="project-kind order-2 order-md-1 bg-dark">{{ project.kind }}</div>
            </div>
            <a class="view-project-details" [innerHTML]="'work.VIEW_DETAILS' | translate"></a>
          </div>
        </div>
      </div>
    </section>
  </ng-template>

  <div class="container mt-5">
    <hr>
  </div>
</div>
