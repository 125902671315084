/**
 *
 * @param array the array to select elements
 * @param amount the amount of elements to select
 * @returns random elements from an array
 */
export function multipleRandomElements(array: any[], amount: number) {
  const shuffled = [...array].sort(() => 0.5 - Math.random());

  return shuffled.slice(0, amount);
}
